/*************************************
* File generated by Slyck IDE
* CookiesDialog Component
*************************************/

import { useState, useEffect, useLayoutEffect, useRef, useMemo, useCallback, Fragment } from 'react';

// state management
import { useDispatch, useSelector } from 'react-redux';
import { showCookiesDialog, hideConsentMessage as _hideConsentMessage } from '../mainSlice';
//assets
import app from '../assets/app.png';
import firebase_template from '../assets/firebase_template.png';
import slyck_project_screen from '../assets/slyck_project_screen.png';
import slyck_logo_bw from '../assets/slyck_logo_bw.svg';
import components_thumb from '../assets/components_thumb.png';
import pages_thumb from '../assets/pages_thumb.png';
import elements_thumb from '../assets/elements_thumb.png';
import download_app_store from '../assets/download_app_store.svg';

// components
import { Link } from "react-router-dom";

import styled from 'styled-components';


/*************************************
* Styled components
*************************************/

const STYLED_ZBGEG7 = styled.div`
  padding: 1.6rem;
  position: fixed;
  border-top: 1px solid #ddd;
  width: 100%;
  z-index: 5;
  background-color: rgba(100,100,100,0.8);
  top: 0;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const STYLED_ZJXJ7L = styled.div`
  padding: 1.6rem;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 1.2rem;
  max-width: 34rem;
`;
const STYLED_UMSOYW = styled.div`
  max-width: var(--max-width);
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  column-gap: 1.2rem;
  flex-direction: column;
  row-gap: 1.6rem;
`;
const STYLED_8SJKDJ = styled.div`
  line-height: 1.4;
`;
const STYLED_ETV4CI = styled.div`
  display: flex;
  gap: 0.8rem;
`;
const STYLED_UEYS1Q = styled.div`
  font-weight: 600;
  margin-bottom: 0.8rem;
`;
const STYLED_NQRSB4 = styled.button`
  font: inherit;
  background: none;
  appearance: none;
  border: 1px solid var(--primary-color);
  color: var(--primary-color);
  padding: 1.2rem 1.6rem;
  border-radius: 0.8rem;
`;
const STYLED_CKJIOD = styled.button`
  background-color: var(--primary-color);
  border: none;
  border-radius: 0.8rem;
  padding: 1.2rem 1.6rem;
  font: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 0.8rem;
  font-weight: 400;
  flex-basis: 8rem;
  flex-shrink: 0;
  white-space: nowrap;
  color: white;
`;


/*************************************
* Component export
*************************************/

export default function CookiesDialog(props) {

  // actions
  const dispatch = useDispatch();
  const hideConsentMessage = useCallback((payload) => dispatch({type: 'hideConsentMessage', payload}), [dispatch]);

  // variables
  const show = useSelector((state) => showCookiesDialog(state, props));
  
  /**
   * Handles the onClick event for the undefined element.
   * @param {Object} event - event object passed from browser
   */
  const onClicknQrSb4 = (event) => {
    
    localStorage.setItem('consentToCookies', 'false');
    hideConsentMessage();
  }
  /**
   * Handles the onClick event for the undefined element.
   * @param {Object} event - event object passed from browser
   */
  const onClickckjIOd = (event) => {
    
    localStorage.setItem('consentToCookies', 'true');
    window.dataLayer = window.dataLayer || [];
    function gtag(){window.dataLayer.push(arguments);}
    gtag('consent', 'default', {
      'ad_storage': 'granted',
      'analytics_storage': 'granted'
    });
    hideConsentMessage();
  }

  return (
    <Fragment>
      {show && (
        <STYLED_ZBGEG7 data-slyck_id="ZBGEg7tZJ5BfMPPUMFMZ">
          <STYLED_ZJXJ7L data-slyck_id="zjxJ7lsJUdBTIcGi12xU">
            <STYLED_UMSOYW data-slyck_id="UMsoYwppBrwBHwlcWNQD">
              <STYLED_8SJKDJ data-slyck_id="8sJkdjITguvtMoeN7StW">
                <STYLED_UEYS1Q data-slyck_id="UEys1Qm6h3NCWjS22ZVL">Cookies 🤤</STYLED_UEYS1Q>
                <span data-slyck_id="XwExP5EZCuqeDsGpIg2B">This website stores cookies on your computer to analyse traffic and improve your website experience.</span>
                {false && (
                  <Link to={"/cookies"} data-slyck_id="Jl1Cz7CQ8UPD0TQz568h">Read our cookie policy</Link>
                )}
              </STYLED_8SJKDJ>
              <STYLED_ETV4CI data-slyck_id="eTv4CiNaDKINlm7zoGdI">
                <STYLED_NQRSB4 data-slyck_id="nQrSb4zzvyZCUeQtlvYc" onClick={onClicknQrSb4}>Decline</STYLED_NQRSB4>
                <STYLED_CKJIOD data-slyck_id="ckjIOdGIfP9UnoEvL8eU" onClick={onClickckjIOd}>Accept</STYLED_CKJIOD>
              </STYLED_ETV4CI>
            </STYLED_UMSOYW>
          </STYLED_ZJXJ7L>
        </STYLED_ZBGEG7>
      )}
    </Fragment>
  );

}
