/*************************************
* File generated by Slyck IDE
* ContentSection Component
*************************************/

import { useState, useEffect, useLayoutEffect, useRef, useMemo, useCallback, Fragment } from 'react';

// state management
import { useDispatch, useSelector } from 'react-redux';
//assets
import app from '../assets/app.png';
import firebase_template from '../assets/firebase_template.png';
import slyck_project_screen from '../assets/slyck_project_screen.png';
import slyck_logo_bw from '../assets/slyck_logo_bw.svg';
import components_thumb from '../assets/components_thumb.png';
import pages_thumb from '../assets/pages_thumb.png';
import elements_thumb from '../assets/elements_thumb.png';
import download_app_store from '../assets/download_app_store.svg';

// components

import styled from 'styled-components';


/*************************************
* Styled components
*************************************/

const STYLED_UNTITLED = styled.div`
  max-width: var(--max-width);
  text-align: left;
  padding: 4rem 0;
  display: grid;
  align-items: center;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 3rem;
  grid-auto-flow: dense;
  justify-items: center;
  top: 4rem;
@media (max-width: 768px) {
    
      flex-direction: column !important;
      display: flex;
    }
`;
const STYLED_TEXT = styled.div`
  
`;
const STYLED_BODY = styled.div`
  line-height: 1.3;
`;


/*************************************
* Component export
*************************************/

export default function ContentSection(props) {
  const { reverse, title, body } = props;

  return (
    <STYLED_UNTITLED className={`content-section ${reverse ? 'reverse' : ''}`} data-slyck_id="jqcpizv3jEnTlfeaqBRN">
      <STYLED_TEXT className={"content-section-text"} data-slyck_id="YFhpNohCo16REqUG90MJ">
        <h2 data-slyck_id="dmOzeHy7f82TriXnqRnh">{title}</h2>
        <STYLED_BODY data-slyck_id="cvf9RgtQMIj5Qtt5b67w">{body}</STYLED_BODY>
      </STYLED_TEXT>
      {props.children}
    </STYLED_UNTITLED>
  );

}
