/*************************************
* File generated by Slyck IDE
* user slice
*************************************/

import { createSlice, createAction } from '@reduxjs/toolkit';


// actions
export const updateUser = createAction('updateUser');

const slice = createSlice({
  name: 'user',initialState: {},
  extraReducers: {
    [updateUser]: (state, action) => {
      const { payload } = action;
      Object.assign(state, payload);
    }
  }
});

export default slice.reducer;

// selectors
export const getUser = (state, props) => {
  return state.user;
};
    