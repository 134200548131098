/*************************************
* File generated by Slyck IDE
* Home Component
*************************************/

import { useState, useEffect, useLayoutEffect, useRef, useMemo, useCallback, Fragment } from 'react';

// state management
import { useDispatch, useSelector } from 'react-redux';
import { getScrollTop, setScrollTop as _setScrollTop, setScrollDirection as _setScrollDirection } from '../mainSlice';
//assets
import app from '../assets/app.png';
import firebase_template from '../assets/firebase_template.png';
import slyck_project_screen from '../assets/slyck_project_screen.png';
import slyck_logo_bw from '../assets/slyck_logo_bw.svg';
import components_thumb from '../assets/components_thumb.png';
import pages_thumb from '../assets/pages_thumb.png';
import elements_thumb from '../assets/elements_thumb.png';
import download_app_store from '../assets/download_app_store.svg';

// components
import Header from "../components/Header";
import HeadingBlock from "../components/HeadingBlock";
import Footer from "../components/Footer";
import { IoLogoApple, IoCreateOutline, IoFileTrayFullOutline, IoCodeSlashOutline, IoCubeOutline, IoEyeOutline, IoLockOpenOutline, IoLibraryOutline, IoDocumentsOutline, IoLayersOutline, IoFitnessOutline, IoPlayCircle, IoRocketOutline, IoLogoFirebase } from "react-icons/io5";
import ContentBlock from "../components/ContentBlock";
import RainbowHeading from "../components/RainbowHeading";
import ContentSection from "../components/ContentSection";

import styled, { keyframes } from 'styled-components';

const pulsatecircle = keyframes`
  0% {
    transform:scale(1);
  }
  50% {
    transform:scale(1.02);
  }
  100% {
    transform:scale(1);
  }
`;
const pulsateCircle1 = keyframes`
  0% {
    transform:scale(1);
  }
  50% {
    transform:scale(1.02);
  }
  100% {
    transform:scale(1);
  }
`;
const heroimageappear = keyframes`
  0% {
    transform:translateY(200px);
  opacity:0.5;
  }
  100% {
    transform:translateY(0);
  opacity:1;
  }
`;

/*************************************
* Styled components
*************************************/

const STYLED_HOMECONTENT = styled.div`
  height: 100%;
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
`;
const STYLED_TOPSECTION = styled.div`
  padding: 1.6rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding-bottom: 6rem;
`;
const STYLED_2QWAFX = styled(HeadingBlock)`
  max-width: 90rem;
  margin-bottom: 8rem;
`;
const STYLED_1HLAYC = styled.div`
  display: flex;
  column-gap: 2rem;
  max-width: var(--max-width);
  margin: 0 auto;
  justify-content: space-between;
  margin-bottom: 8rem;
  row-gap: 2rem;
@media (max-width: 768px) {
    
      flex-direction: column;
      padding: 2rem;
    }
`;
const STYLED_ZMTMC1 = styled.div`
  padding: 1.6rem;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  column-gap: 7rem;
  row-gap: 8rem;
  margin-bottom: 12rem;
@media (max-width: 768px) {
    
      row-gap: 4rem;
      justify-content: flex-start;
      margin-bottom: 6rem;
    }
`;
const STYLED_MZMEP6 = styled.div`
  padding: 1.6rem;
`;
const STYLED_NAWWQT = styled.div`
  & input.email:focus {
    outline: none;
    box-shadow: 0 0 0 2px #167ffe;
    border-color: #167ffe;
  }
  
  & h2 {
    margin: -0.4rem 0 0;
  }
  
  & #mc-embedded-subscribe {
    background-color: var(--primary-color);
    color: white;
    border: none;
    border-radius: 0.5rem;
    padding: 1rem 1.2rem;
    font: inherit;
    width: 100%;
    margin-top: 0.8rem;
    appearance: none;
  }
  
  & input.email {
    border: 1px solid #ddd;
    border-radius: 0.8rem;
    padding: 1rem 1.2rem;
    font: inherit;
    width: 100%;
  }
  
  & #mc_embed_signup_scroll {
    display: grid;
    align-items: center;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 2rem;
  }
  @media (max-width: 768px) {
    & #mc_embed_signup_scroll {
      flex-direction: column;
      row-gap: 2rem;
      align-items: stretch;
      display: flex;
    }
  }
  
  padding: 1.6rem;
  max-width: 68rem;
  margin: 0 auto;
`;
const STYLED_WNGAJS = styled.div`
  padding: 1.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  column-gap: 10rem;
  row-gap: 8rem;
  margin-bottom: 12rem;
@media (max-width: 768px) {
    
      row-gap: 4rem;
      margin-bottom: 6rem;
      justify-content: flex-start;
    }
`;
const STYLED_UKFOOU = styled.div`
  padding: 1.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  column-gap: 10rem;
  row-gap: 8rem;
`;
const STYLED_MIDDLESECTION = styled.div`
  padding: 1.6rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: var(--sub-heading-size);
  text-align: center;
@media (max-width: 768px) {
    
      font-size: 2rem;
      text-align: left;
    }
`;
const STYLED_HERO = styled.div`
  width: 100%;
  max-width: var(--max-width);
  position: relative;
  z-index: 1;
`;
const STYLED_HEROLOGO = styled.img`
  position: fixed;
  top: -8rem;
  opacity: 0.02;
  right: -2rem;
  width: 50%;
  z-index: -1;
`;
const STYLED_HEROTEXT = styled.div`
  margin: 0rem auto 4rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
@media (max-width: 768px) {
    
      text-align: left;
      align-items: flex-start;
    }
`;
const STYLED_CIRCLES = styled.div`
  position: relative;
  max-width: 80rem;
  margin: 0 auto;
  display: flex;
  align-items: center;

  &::before {
    content: "";
    width: 78%;
    display: block;
    padding-top: 78%;
    position: absolute;
    background-color: hsl(215deg 94% 65% / 18%);
    border-radius: 50%;
    left: -4rem;
    z-index: -2;
    animation: ${pulsateCircle1} 5s ease-in-out infinite;
    box-shadow: 0 0 80px hsl(215deg 94% 65% / 18%);
    margin-top: -2%;
  }
  
  &::after {
    content: "";
    width: 78%;
    display: block;
    padding-top: 78%;
    position: absolute;
    background-color: hsl(322deg 94% 65% / 18%);
    border-radius: 50%;
    right: -4rem;
    z-index: -2;
    animation: ${pulsatecircle} 5s ease-in-out infinite;
    box-shadow: 0 0 80px hsl(322deg 94% 65% / 18%);
    margin-top: -2%;
  }
`;
const STYLED_56EKRR = styled.div`
  display: flex;
  align-items: center;
  column-gap: 1.6rem;
`;
const STYLED_MAINHEADING = styled.h1`
  line-height: 1;
  margin: 0.8rem 0 1.6rem;
  max-width: 69rem;
  font-weight: 600;
@media (max-width: 768px) {
    
      font-size: 5.6rem;
    }
`;
const STYLED_SUBHEADING = styled.div`
  font-size: var(--sub-heading-size);
  line-height: 1.2;
  margin-bottom: 2rem;
  max-width: 72rem;
@media (max-width: 768px) {
    
      font-size: 2.6rem;
    }
`;
const STYLED_9FF60Z = styled.button`
  color: var(--primary-color);
  appearance: none;
  background: none;
  padding: 0;
  font: inherit;
  border: none;
  margin-bottom: 2.2rem;
  font-weight: 400;
`;
const STYLED_VKVTU3 = styled.div`
  display: flex;
  align-items: center;
  column-gap: 1.6rem;
@media (max-width: 768px) {
    
      flex-direction: column;
      align-items: flex-start;
      row-gap: 1.2rem;
    }
`;
const STYLED_KXZCOM = styled.div`
  margin-top: 1.6rem;
  opacity: 0.75;
`;
const STYLED_SLYCKTITLE = styled.div`
  font-size: var(--sub-heading-size);
  font-weight: 500;
@media (max-width: 768px) {
    
      font-size: inherit;
    }
`;
const STYLED_VOLJVR = styled.div`
  background-color: hsl(212deg,95%,93%);
  padding: 0.4rem 0.8rem;
  border-radius: 0.8rem;
  color: hsl(212deg,90%,50%);
  font-weight: 500;
`;
const STYLED_DOWNLOAD = styled.a`
  & svg {
    font-size: 1.8rem;
  }
  
  background-color: var(--primary-color);
  padding: 1.2rem 1.6rem;
  color: white;
  border-radius: 0.8rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  column-gap: 1.2rem;
`;
const STYLED_DOWNLOADCOPY = styled.a`
  & svg {
    font-size: 1.8rem;
  }
  
  background-color: var(--primary-color);
  padding: 1.2rem 1.6rem;
  color: white;
  border-radius: 0.8rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  column-gap: 1.2rem;
`;
const STYLED_J77KMB = styled.div`
  text-align: left;
  line-height: 1.4;
`;
const STYLED_UKUTBK = styled.div`
  font-size: 1.1rem;
  font-weight: 300;
`;
const STYLED_9RSEXL = styled.div`
  text-align: left;
  line-height: 1.4;
`;
const STYLED_NSZNKR = styled.div`
  font-size: 1.1rem;
  font-weight: 300;
`;
const STYLED_HEROIMAGECONTAINER = styled.div`
  display: flex;
  align-items: center;
`;
const STYLED_HEROIMAGECOPY = styled.img`
  margin-left: -2%;
  margin-right: -2%;
  width: 104%;
  max-width: 1312px;
  height: auto;
  transform: scale(0.88) translateX(-11%);
  z-index: -1;
  display: inline-block;
  position: absolute;
  opacity: 0.8;
`;
const STYLED_HEROIMAGE = styled.img`
  margin-left: -2%;
  margin-right: -2%;
  width: 104%;
  max-width: 1312px;
  height: auto;
  display: inline-block;
`;
const STYLED_HEROIMAGECOPY2 = styled.img`
  margin-left: -2%;
  margin-right: -2%;
  width: 104%;
  max-width: 1312px;
  height: auto;
  transform: scale(0.88) translateX(11%);
  z-index: -1;
  display: inline-block;
  position: absolute;
  opacity: 0.8;
`;
const STYLED_7I5QQI = styled.div`
  @media (max-width: 768px) {
    & iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
  
  @media (max-width: 768px) {
    
      float: none;
      clear: both;
      width: 100%;
      position: relative;
      padding-bottom: 56.25%;
      padding-top: 25px;
      height: 0;
    }
`;
const STYLED_XW8AE4 = styled.div`
  @media (max-width: 768px) {
    & iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
  
  @media (max-width: 768px) {
    
      float: none;
      clear: both;
      width: 100%;
      position: relative;
      padding-bottom: 56.25%;
      padding-top: 25px;
      height: 0;
    }
`;
const STYLED_1XQFN2 = styled.div`
  & > a svg + svg {
    font-size: 18rem;
    margin-top: -9rem;
    margin-left: -9rem;
    opacity: 0.1;
  }
  
  & > a {
    background-color: #f7f8f9;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: column;
    row-gap: 1.2rem;
    position: relative;
    font-weight: 500;
    color: #444;
    padding: 1.6rem;
    border-radius: 1.6rem;
  }
  
  & > a svg {
    font-size: 8rem;
    position: absolute;
    top: 46%;
    left: 50%;
    margin-top: -4rem;
    margin-left: -4rem;
    color: var(--primary-color);
  }
  
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 2rem;
  max-width: var(--max-width);
  margin: 0 auto 8rem;
  row-gap: 2rem;
@media (max-width: 768px) {
    
      grid-template-columns: 1fr;
    }
`;
const STYLED_IDEASCONTAINER = styled.div`
  
`;
const STYLED_UNTITLED = styled.div`
  padding: 6rem 1.6rem;
  background-color: rgba(0,0,0,0.02);
  width: 100vw;
  margin: 3rem -1.6rem;
`;
const STYLED_LOCKSECTION = styled.div`
  max-width: 90rem;
  padding: 4rem 0;
`;
const STYLED_SIMPLIFYIMG = styled.img`
  position: sticky;
  top: 0;
`;
const STYLED_SETUPIMG = styled.img`
  width: 472px;
`;
const STYLED_ORGANISEIMG = styled.img`
  width: 735px;
`;
const STYLED_ENVIRONMENTIMG = styled.img`
  width: 595.5px;
`;
const STYLED_SHARINGIMG = styled.img`
  width: 472px;
`;
const STYLED_VERSIONIMG = styled.img`
  width: 575.5px;
`;
const STYLED_INTEGRATIONIMAGES = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 3rem;
  align-items: center;
  justify-items: center;
  width: 100%;
grid-template-columns: repeat(2, 1fr);
    row-gap: 3rem;
`;
const STYLED_REDUX = styled.img`
  width: 13rem;
@media (max-width: 768px) {
    
      width: 8rem;
    }
`;
const STYLED_FIREBASE = styled.img`
  width: 8rem;
@media (max-width: 768px) {
    
      width: 5rem;
    }
`;
const STYLED_CAPACITOR = styled.img`
  width: 8.5rem;
@media (max-width: 768px) {
    
      width: 5.5rem;
    }
`;
const STYLED_NPM = styled.img`
  width: 15rem;
@media (max-width: 768px) {
    
      width: 9rem;
    }
`;
const STYLED_INTEGRATIONIMGCOPY = styled.img`
  width: 575.5px;
`;
const STYLED_INTEGRATIONIMG = styled.img`
  width: 575.5px;
`;
const STYLED_INTEGRATIONBODYCOPY3 = styled.div`
  font-size: 3.8rem;
  line-height: 1.3;
  font-weight: 500;
  margin-bottom: 4rem;
`;


/*************************************
* Component export
*************************************/

export default function Home(props) {

  // actions
  const dispatch = useDispatch();
  const setScrollTop = useCallback((payload) => dispatch({type: 'setScrollTop', payload}), [dispatch]);
  const setScrollDirection = useCallback((payload) => dispatch({type: 'setScrollDirection', payload}), [dispatch]);

  // variables
  const scrollTop = useSelector((state) => getScrollTop(state, props));
  const containerClass = useMemo(() => (scrollTop <= 360 || !scrollTop) ? 'scrolltop' : '', [scrollTop]);

  // side effects
  useEffect(() => {
    setScrollTop(0)
  }, [setScrollTop]);
  
  /**
   * Handles the onScroll event for the HomeContent element.
   * @param {Object} event - event object passed from browser
   */
  const onScrollHomeContent = (event) => {
    
    setScrollTop(event.target.scrollTop);
    if (event.target.scrollTop > scrollTop) {
      setScrollDirection('down');
    } else {
      setScrollDirection('up');
    }
  }
  /**
   * Handles the onClick event for the Download element.
   * @param {Object} event - event object passed from browser
   */
  const onClickDownload = (event) => {
    
    window.gtag('event', 'download_metric', {'download': 'intel'});
  }
  /**
   * Handles the onClick event for the DownloadCopy element.
   * @param {Object} event - event object passed from browser
   */
  const onClickDownloadCopy = (event) => {
    
    window.gtag('event', 'download_metric', {'download': 'apple'});
  }

  return (
    <STYLED_HOMECONTENT onScroll={onScrollHomeContent} className={containerClass} data-slyck_id="XVlxyEqpg7Q3k1N5pX41">
      <Header data-slyck_id="9gKL7HD83mIm4w5iCjzE" />
      <STYLED_TOPSECTION data-slyck_id="knbbcyxbznqaNta6YcAp">
        <STYLED_HERO data-slyck_id="L5TPpiA1DJ2sx129ss8S">
          {false && (
            <STYLED_HEROLOGO src={slyck_logo_bw} data-slyck_id="xQRrfZx6VN2v0OEdHkG4" />
          )}
          <STYLED_HEROTEXT data-slyck_id="tH3ME5Ng3q8aGA04ym66">
            <STYLED_56EKRR data-slyck_id="56EkrrPjTXlEZvtZimL6">
              <STYLED_SLYCKTITLE data-slyck_id="JqOXCZ7x9OQMIZNhau0x">Slyck IDE</STYLED_SLYCKTITLE>
              <STYLED_VOLJVR data-slyck_id="vOljVR3kQz3PLxA4LbwC">Public Beta</STYLED_VOLJVR>
            </STYLED_56EKRR>
            <STYLED_MAINHEADING data-slyck_id="SG6A2gjOAVMLGW4EAMPK">Build successful React apps.</STYLED_MAINHEADING>
            <STYLED_SUBHEADING data-slyck_id="R0uLXhzXOsgZ1Kos1tDH">Slyck helps developers deliver better products faster and easier by streamlining the React development experience.</STYLED_SUBHEADING>
            {false && (
              <STYLED_9FF60Z data-slyck_id="9FF60zrOKT9jwvVTbZYT">Watch the video</STYLED_9FF60Z>
            )}
            <STYLED_VKVTU3 data-slyck_id="vkVtU3162nOL1VnhIoof">
              <STYLED_DOWNLOAD onClick={onClickDownload} href={"https://download.slyck.app/download/mac"} data-slyck_id="LbRgfSR5j5qMB0FZtg5M">
                <IoLogoApple data-slyck_id="hZtvtv3FENN2jDXcTwBK" />
                <STYLED_J77KMB data-slyck_id="j77kmboMp7EtjW5RJyit">
                  <div data-slyck_id="O8dEJB8Xh8Jqd61i4cyA">Download for Intel chip</div>
                  {false && (
                    <STYLED_UKUTBK data-slyck_id="UKuTbKsXoSvRK5XEY7dJ">Apple silicon</STYLED_UKUTBK>
                  )}
                </STYLED_J77KMB>
              </STYLED_DOWNLOAD>
              <STYLED_DOWNLOADCOPY onClick={onClickDownloadCopy} href={"https://download.slyck.app/download/mac_arm64"} data-slyck_id="0duzEFrVV5QLjSLGVElx">
                <IoLogoApple data-slyck_id="Jbr8f2dkmtRGkO5zdfrx" />
                <STYLED_9RSEXL data-slyck_id="9RsEXlD6CASgmuevdp4U">
                  <div data-slyck_id="HHn7SDhrgcEr3L2ilkxO">Download for Apple chip</div>
                  {false && (
                    <STYLED_NSZNKR data-slyck_id="nsZnKrsviQhlkYj7VJum">Apple silicon</STYLED_NSZNKR>
                  )}
                </STYLED_9RSEXL>
              </STYLED_DOWNLOADCOPY>
            </STYLED_VKVTU3>
            <STYLED_KXZCOM data-slyck_id="kXzCoMnJBEWMj0WeHbBx">available for macOS</STYLED_KXZCOM>
          </STYLED_HEROTEXT>
          <STYLED_CIRCLES data-slyck_id="nhUJWpmKtyYORZH1kTvF">
            <STYLED_HEROIMAGECONTAINER style data-slyck_id="aNStfYXbbXOuDoWNWbDu">
              <STYLED_HEROIMAGECOPY width={"2624px"} height={"1824px"} src={slyck_project_screen} data-slyck_id="X2Zuom1IDQuh7cHQOEjC" />
              <STYLED_HEROIMAGE src={slyck_project_screen} width={"2624px"} height={"1824px"} data-slyck_id="UIveiuFkrm7NOXCrrWjv" />
              <STYLED_HEROIMAGECOPY2 src={slyck_project_screen} width={"2624px"} height={"1824px"} data-slyck_id="vzsUOC3CGuTPgDtDTMQG" />
            </STYLED_HEROIMAGECONTAINER>
          </STYLED_CIRCLES>
        </STYLED_HERO>
      </STYLED_TOPSECTION>
      <STYLED_2QWAFX title subtitle={"Slyck is designed to make React development as simple and intuitive as possible. It automates time-consuming tasks and presents you with all the tools you need in one easy-to-use interface. In other words: it's the perfect environment for you to innovate."} data-slyck_id="2QwafxpCMwLVypOvHZ1s" />
      {false && (
        <STYLED_1HLAYC data-slyck_id="1hLaYC8yTV873graueoR">
          <div data-slyck_id="EOlgMOUzMLkFcRb8UeGk">
            <STYLED_7I5QQI data-slyck_id="7I5qQIxZRWNVBaoW9ulj"><iframe width="560" height="315" src="https://www.youtube.com/embed/v_hR4K4auoQ?controls=0" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></STYLED_7I5QQI>
          </div>
          <div data-slyck_id="KJB16y6BzgxdbA5BuVAw">
            <STYLED_XW8AE4 data-slyck_id="xW8ae4HmDjhTjXMMD3bU"><iframe width="560" height="315" src="https://www.youtube.com/embed/Ofux_4c94FI?controls=0" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></STYLED_XW8AE4>
          </div>
        </STYLED_1HLAYC>
      )}
      <STYLED_ZMTMC1 data-slyck_id="zMtmC13goOWZZEuM2zSn">
        <ContentBlock title={"Effortless project setup"} body={"Single click setup with a modern stack and build pipeline optimized for production – no command line required."} icon={<IoCreateOutline />} data-slyck_id="KJPrqo7dL7bvqldBeJhL" />
        <ContentBlock body={"Decoupling your code from files means one less thing to think about. Slyck creates and manages your files in the background."} title={"Automated file management"} icon={<IoFileTrayFullOutline />} data-slyck_id="I8gBCNNvyoeJNcmnnYP9" />
        <ContentBlock title={"Write code that matters"} body={"Slyck automatically generates boilerplate code for you, so you can focus on writing high impact code that creates value."} icon={<IoCodeSlashOutline />} data-slyck_id="KQtZWCWuRTNY1lzknqwB" />
        <ContentBlock body={"Easily develop, preview and test components in isolation, with built-in component story support."} icon={<IoCubeOutline />} title={"Component driven development"} data-slyck_id="9CYbwhFCmZipoMa1Emxp" />
        <ContentBlock icon={<IoEyeOutline />} body={"Built-in preview with Chrome DevTools lets you instantly view changes and inspect elements."} title={"Interactive preview"} data-slyck_id="wSlLrRuZCRkC44SNjmfn" />
        <ContentBlock icon={<IoLockOpenOutline />} title={"Never locked in"} body={"Slyck compiles standard React code, so if you decide to stop using Slyck you can pick up your projects with any other code editor."} data-slyck_id="DFVdwheBRqfe7GfMUG1z" />
        <ContentBlock body={"Slyck makes it easy to save components and reuse them in all of your projects."} title={"Build a component library"} icon={<IoLibraryOutline />} data-slyck_id="F4P5xzfqH1LuASfOCgCU" />
        <ContentBlock body={"Give yourself a head start – download or create your own project templates to rapidly build new products."} title={"Create project templates"} icon={<IoDocumentsOutline />} data-slyck_id="g1E24vyYtfI4FxW1A5JM" />
        <ContentBlock title={"Integrations"} icon={<IoLayersOutline />} body={"Slyck integrates awesome libraries and SDKs like Redux, Firebase and Capacitor to make them super easy to use in your projects."} data-slyck_id="Jn1xvV1zBGPeu9euI0qD" />
        {false && (
          <ContentBlock icon={<IoFitnessOutline />} title={"Reduce your cognitive load"} body={"Slyck‘s UI is designed to reduce distractions. Relevant code is displayed based on the context – no need to peruse files."} data-slyck_id="4HQJr58XSXqKFDhuPGxd" />
        )}
      </STYLED_ZMTMC1>
      <HeadingBlock title={"Get the most out of Slyck"} subtitle={"Check out the video lessons to learn the basics."} data-slyck_id="og6fe0Lsb1xLcvGVDzjE" />
      <STYLED_MZMEP6 data-slyck_id="Mzmep6oQ5dLo3ZDcwZPR">
        <STYLED_1XQFN2 data-slyck_id="1xQfN2TZ1i8uRLBeGkXC">
          <a href={"https://youtu.be/NzaxKD5tvE4"} target={"_blank"} data-slyck_id="ag1jQqYHnerW05mmEYK1">
            <IoPlayCircle data-slyck_id="W45rsS9VliqZBen62isF" />
            <img src={firebase_template} data-slyck_id="2tiiJxLPWyxIaEY4ABjK" />
            <div data-slyck_id="ZEbTToSsA8uPnQvoEU3G">Firebase signup flow + Mixpanel analytics</div>
          </a>
          <a href={"https://youtu.be/22ybdMTSmxY"} target={"_blank"} data-slyck_id="Wsn9WpIXjGvE71BZDSb9">
            <IoPlayCircle data-slyck_id="jo81NYfnTdyupk7gqfTs" />
            <img src={elements_thumb} data-slyck_id="Nr2UDzdHZcIUnQdLoj7U" />
            <div data-slyck_id="kLtbhipOY5E3hG4qe4Vk">How to create elements</div>
          </a>
          <a href={"https://youtu.be/VYNQ9kH3HSE"} target={"_blank"} data-slyck_id="wjgZ2xiKPR3GtLTopY2j">
            <IoPlayCircle data-slyck_id="M7o2MaZj6WMtB6HypuK8" />
            <img src={components_thumb} data-slyck_id="QaAxsXOKfR8Rf3yNHLFQ" />
            <div data-slyck_id="gYw5i27hMBMey0nQ0vYc">How to create components</div>
          </a>
          <a target={"_blank"} href={"https://youtu.be/HNk9ggfTIC0"} data-slyck_id="b7jee7V9i2XeroDnQmML">
            <IoPlayCircle data-slyck_id="jOpjQdizYDQAD4umrGQC" />
            <img src={pages_thumb} data-slyck_id="zsWOpseeHS2C1CBdbcjJ" />
            <div data-slyck_id="SdKJMduCBMc9qBDnrgiL">How to create pages</div>
          </a>
        </STYLED_1XQFN2>
      </STYLED_MZMEP6>
      <STYLED_NAWWQT data-slyck_id="nawWqtkPmuIv2O0nvRAX">
        <div id="mc_embed_signup">
          <form action="https://app.us6.list-manage.com/subscribe/post?u=6198a1286220b12a533fff0f4&amp;id=4606d3d2af" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank" noValidate>
            <div id="mc_embed_signup_scroll">
              <h2>Sign up to the newsletter</h2>
              <div>
                <div className="mc-field-group">
                  <input type="email" name="EMAIL" placeholder="Email Address" aria-label="Email Address" className="required email" id="mce-EMAIL" />
                </div>
                <div id="mce-responses" className="clear foot">
                  <div className="response" id="mce-error-response" style={{display:'none'}}></div>
                  <div className="response" id="mce-success-response" style={{display:'none'}}></div>
                </div>
                <div style={{position: 'absolute', left: '-5000px'}} aria-hidden="true"><input type="text" name="b_6198a1286220b12a533fff0f4_4606d3d2af" tabIndex="-1" /></div>
                <div className="optionalParent">
                  <div className="clear foot">
                    <input type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" className="button" />
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </STYLED_NAWWQT>
      {false && (
        <HeadingBlock subtitle={"When you optimize the development process and remove mundane time waste you open a clear path for innovation."} title={"Time to innovate."} data-slyck_id="Pq5twV5ctrZFWqZfUD6a" />
      )}
      {false && (
        <STYLED_WNGAJS data-slyck_id="wNGaJsDk85F0MiAPjzQW">
          <ContentBlock icon={<IoLayersOutline />} body={"Slyck integrates powerful technologies such as Redux, Firebase and Capacitor and exposes them in an easy to use interface."} title={"Powerful integrations"} data-slyck_id="tsPy1b37Pm83jZuMauwv" />
        </STYLED_WNGAJS>
      )}
      {false && (
        <HeadingBlock title={"Lightning fast development."} subtitle={"Whether you‘re building an MVP or iterating an existing product, Slyck is a catalyst for innovation."} data-slyck_id="qz2bjHxfXz7sQIUOgUyG" />
      )}
      {false && (
        <STYLED_UKFOOU data-slyck_id="UKFoouP25RtWsVcXgcdx">
          <ContentBlock body={"Relevant code is grouped and displayed based on the context – e.g. for elements you see the associated variables, events and styles. No more searching through files."} icon={<IoRocketOutline />} title={"Deploy"} data-slyck_id="woxuSkyxN93TYXMa1UyD" />
          <ContentBlock body={"Relevant code is grouped and displayed based on the context – e.g. for elements you see the associated variables, events and styles. No more searching through files."} icon={<IoLogoFirebase />} title={"Firebase integration"} data-slyck_id="aSm2bcMW43OVUA3DobHs" />
        </STYLED_UKFOOU>
      )}
      {false && (
        <STYLED_MIDDLESECTION data-slyck_id="21aGTLcVcCPhFq4gsn51">
          <RainbowHeading text={"Write less, do more."} data-slyck_id="lbnB9I74HbYgNVbvr6TL" />
          <STYLED_IDEASCONTAINER data-slyck_id="HZ5X7Y7hCyF8n51MSTgO">
            <ContentSection title={"Write less, do more."} reverse={false} body={"Slycks AI engine creates your app‘s files and boilerplate code for you. The result is a reduced workload and a truly efficient wokflow – every line of code you write contributes to your product."} data-slyck_id="DMAVbd28wbzjV9iTaB5q">
              <STYLED_SIMPLIFYIMG width={"1468"} src={''} height={"1028"} data-slyck_id="GebYjeWpffDt89wPupwN" />
            </ContentSection>
            <ContentSection title={"Lightning fast setup."} reverse={true} body={"It takes just seconds to start a new project in Slyck. Your developer environment is set up for you in the background. And project templates massively cut down the time it takes to get up and running."} data-slyck_id="C1ek15r5WZlA6shIoy0T">
              <STYLED_SETUPIMG width={944} height={1064} src={''} data-slyck_id="8436HCD2oXXQe0XxFuco" />
            </ContentSection>
          </STYLED_IDEASCONTAINER>
          <RainbowHeading text={"Visionary DevX."} data-slyck_id="EMqvvviOursSgYmxCAUz" />
          <ContentSection body={"In Slyck, relevant code is grouped and displayed based on the context – like if you‘re viewing an element, you'll see all its associated code and styles. No more searching through files; it's all right at your fingertips. "} title={"Intelligent code organisation."} data-slyck_id="qrEjpjv2Z1m0hlBNl2R6">
            <STYLED_ORGANISEIMG width={1470} height={1061} src={''} data-slyck_id="I3KeVmDA2NidlsXvpIKv" />
          </ContentSection>
          <ContentSection body={"The built in preview instantly shows your changes take effect as you type. It even comes equipped with the excellent Chrome DevTools – perfect to help you debug and measure performance."} title={"Preview as you code."} reverse={true} data-slyck_id="b6HTRfCLFsw96K0wolsf">
            <STYLED_ENVIRONMENTIMG height={1112} width={1150} src={''} data-slyck_id="G17MYWd9a5CUXJJA3nT0" />
          </ContentSection>
          <RainbowHeading text={"Designed for teams."} data-slyck_id="tF3jEBAIbr9cHJYPDFb7" />
          <ContentSection title={"Sharing is caring."} body={"Collaborate on projects and templates by making them available to your team. You can even share individual components or make them public for the benefit of everyone."} data-slyck_id="SbutFVciycx7WVanCQet">
            <STYLED_SHARINGIMG src={''} width={944} height={1064} data-slyck_id="ogq8zT6IyGNQFbfjG2xf" />
          </ContentSection>
          <ContentSection title={"Version control"} reverse={true} body={"Need to revert to a previous version? No problem, version control has you covered. The push, pull and merge features allow teams to keep projects in sync. Instantly familiar for Git users."} data-slyck_id="xVuSRM3ZDQTPFcUdOtUw">
            <STYLED_VERSIONIMG width={1151} src={''} height={1002} data-slyck_id="8Yib2n4Jjk4NERoEDHUA" />
          </ContentSection>
          <RainbowHeading text={"Integrate your tools."} data-slyck_id="x2DcDiY8WDLf4nS2TnW1" />
          <STYLED_UNTITLED data-slyck_id="jmQDt1QX3nQa9QfmzfI7">
            <STYLED_INTEGRATIONIMAGES data-slyck_id="az0rrhe1dGMcRwgKj5j8">
              <STYLED_REDUX src={''} data-slyck_id="aTL2lqg2RUcg6kYIrOnv" />
              <STYLED_FIREBASE src={''} data-slyck_id="xYXBISRwHAhY1e6J5B4q" />
              <STYLED_CAPACITOR src={''} data-slyck_id="s0WBCCUfZJ49VrrX35Dq" />
              <STYLED_NPM src={''} data-slyck_id="D1iIOGFZWqMBwAv1xNRp" />
            </STYLED_INTEGRATIONIMAGES>
          </STYLED_UNTITLED>
          <ContentSection body={"Slyck comes with Redux state management baked into the UI, giving you the convenience of global state without the complex boilerplate."} title={"Best in class state management."} data-slyck_id="5szQy4zoogLxkWD8oe1P">
            <STYLED_INTEGRATIONIMGCOPY height={"1002px"} width={"1151px"} src={''} data-slyck_id="2RdkdbNp8mQkgABfsIBy" />
          </ContentSection>
          <ContentSection reverse={true} title={"Build production grade apps."} body={"Connect your frontend to powerful backend services with Firebase integration. Build iOS and Android apps with Capacitor. Browse and install packages from npm."} data-slyck_id="JQE6kTNgvZUAwV0qtqAh">
            <STYLED_INTEGRATIONIMG src={''} width={"1151px"} height={"1002px"} data-slyck_id="M5ZpmlPnwCSK20p4himP" />
          </ContentSection>
          <RainbowHeading text={"You‘re in control."} data-slyck_id="JmOvHg5CasyK52DbgXjW" />
          <STYLED_LOCKSECTION data-slyck_id="194GMTGizy2TzF9SZ38r">
            <h2 data-slyck_id="F4eQtUd0vyUr02lFYZeY">Never locked in.</h2>
            <STYLED_INTEGRATIONBODYCOPY3 data-slyck_id="aPfFyHs4MnkyTr03Es2M">From building prototypes to fully fledged apps – you decide how you want to use Slyck. Your code is continuously compiled to files, so you‘re never locked in. Want to move on from Slyck? Just take your code and continue developing in any code editor.</STYLED_INTEGRATIONBODYCOPY3>
            <img src={''} data-slyck_id="YU3zzvBUBogIIGO88TGH" />
          </STYLED_LOCKSECTION>
        </STYLED_MIDDLESECTION>
      )}
      <Footer data-slyck_id="u8uButYcIwbhDTC0uHFp" />
    </STYLED_HOMECONTENT>
  );

}
