/*************************************
* File generated by Slyck IDE
* App Component
*************************************/

import { useState, useEffect, useLayoutEffect, useRef, useMemo, useCallback, Fragment } from 'react';

// state management
import { useDispatch, useSelector } from 'react-redux';
import { updateUser as _updateUser } from './userSlice';
import { getProPlanPrice, getTeamsPlanPrice, showConsentMessage as _showConsentMessage, setPlanPrice as _setPlanPrice } from './mainSlice';
//assets
import app from './assets/app.png';
import firebase_template from './assets/firebase_template.png';
import slyck_project_screen from './assets/slyck_project_screen.png';
import slyck_logo_bw from './assets/slyck_logo_bw.svg';
import components_thumb from './assets/components_thumb.png';
import pages_thumb from './assets/pages_thumb.png';
import elements_thumb from './assets/elements_thumb.png';
import download_app_store from './assets/download_app_store.svg';

// components
import { onAuthStateChanged, getAuth, isSignInWithEmailLink, signInWithEmailLink } from "firebase/auth";
import CookiesDialog from "./components/CookiesDialog";
import { Routes, Route } from "react-router-dom";
import Templates from "./pages/Templates";
import Upgrade from "./pages/Upgrade";
import Privacy from "./pages/Privacy";
import Pricing from "./pages/Pricing";
import Home from "./pages/Home";

import styled from 'styled-components';



/*************************************
* Component export
*************************************/

export default function App(props) {

  // actions
  const dispatch = useDispatch();
  const showConsentMessage = useCallback((payload) => dispatch({type: 'showConsentMessage', payload}), [dispatch]);
  const updateUser = useCallback((payload) => dispatch({type: 'updateUser', payload}), [dispatch]);
  const setPlanPrice = useCallback((payload) => dispatch({type: 'setPlanPrice', payload}), [dispatch]);

  // variables
  const proPlanPrice = useSelector((state) => getProPlanPrice(state, props));
  const teamsPlanFeatures = useMemo(() => ['All Pro features', 'Team management', 'Shared projects, templates + components', 'Multi User Projects'], []);
  const freePlanFeatures = useMemo(() => ['AI React compiler','Intelligent code organisation','Built-in preview with Chrome DevTools','Component ecosystem','Integrations'], []);
  const proPlanFeatures = useMemo(() => ['All Blaze features', 'Version Control', 'Save Components'], []);
  const teamsPlanPrice = useSelector((state) => getTeamsPlanPrice(state, props));

  // side effects
  useEffect(() => {
    if (!['true', 'false'].includes(localStorage.getItem('consentToCookies'))) {
      showConsentMessage(true);
    }
  }, [showConsentMessage]);

  useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        const uid = user.uid;
        updateUser({...user, authState: 'SIGNED_IN'});
        // ...
      } else {
        // User is signed out
        // ...
        updateUser({authState: 'SIGNED_OUT'});
      }
    });
  }, [updateUser]);

  useEffect(() => {
    const auth = getAuth();
    // Confirm the link is a sign-in with email link.
    if (isSignInWithEmailLink(auth, window.location.href)) {
      // Additional state parameters can also be passed via URL.
      // This can be used to continue the user's intended action before triggering
      // the sign-in operation.
      // Get the email if available. This should be available if the user completes
      // the flow on the same device where they started it.
      let email = window.localStorage.getItem('emailForSignIn');
      if (!email) {
        // User opened the link on a different device. To prevent session fixation
        // attacks, ask the user to provide the associated email again. For example:
        email = window.prompt('Please provide your email for confirmation');
      }
      // The client SDK will parse the code from the link for you.
      signInWithEmailLink(auth, email, window.location.href)
        .then((result) => {
          // Clear email from storage.
          window.localStorage.removeItem('emailForSignIn');
          // You can access the new user via result.user
          // Additional user info profile not available via:
          // result.additionalUserInfo.profile == null
          // You can check if the user is new or existing:
          // result.additionalUserInfo.isNewUser
          // Navigate to index page
          // navigate('/', {replace: true});
        })
        .catch((error) => {
          // Some error occurred, you can inspect the code: error.code
          // Common errors could be invalid email and invalid or expired OTPs.
        });
    }
  }, []);

  useEffect(() => {
    const { Paddle } = window;
    if (Paddle) {
      Paddle.Product.Prices(741066, (data) => {
        setPlanPrice({
          plan: 'pro',
          price: data.price.gross
        });
      });
      Paddle.Product.Prices(741068, (data) => {
        const priceSplit = data.price.gross.split('.');
        setPlanPrice({
          plan: 'teams',
          price: priceSplit.length && priceSplit[1] === '00' ? priceSplit[0] : data.price.gross
        });
      });
    }
  }, [setPlanPrice]);

  return (
    <Fragment>
      <CookiesDialog data-slyck_id="a2CibNsTXpcmgQ3DXswB" />
      <Routes data-slyck_id="riI7aIzI3gqbWmuG7PbB">
        <Route element={<Templates />} path={"/templates"} data-slyck_id="gOdqjIjfPHbtBdtyy1cj" />
        <Route path={"/upgrade"} element={<Upgrade />} data-slyck_id="ISq15iojVEZQEZs6WOqQ" />
        <Route element={<Privacy />} path={"/privacy"} data-slyck_id="c3ZKTgXysL0HAo0Eb1r2" />
        <Route path={"/pricing"} element={<Pricing />} data-slyck_id="d3qsjmhZ1HzZIWakb7o3" />
        <Route path={"/"} element={<Home />} data-slyck_id="hhlUN8eUxYDJGfwVGVkQ" />
      </Routes>
    </Fragment>
  );

}
