import { useState, useEffect } from 'react';


const componentMap = {}

export default function Story() {
  const [component, setComponent] = useState();
  const [story, setStory] = useState();
  useEffect(() => {
    document.addEventListener('storySelected', (e) => { setComponent(e.detail.component); setStory(e.detail.story); })
  },[]);

  const StoryComp = componentMap[component]?.[story];
  return !StoryComp ? '' : <StoryComp {...StoryComp.args} />
}