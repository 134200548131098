/*************************************
* File generated by Slyck IDE
* Template Component
*************************************/

import { useState, useEffect, useLayoutEffect, useRef, useMemo, useCallback, Fragment } from 'react';

// state management
import { useDispatch, useSelector } from 'react-redux';
//assets
import app from '../assets/app.png';
import firebase_template from '../assets/firebase_template.png';
import slyck_project_screen from '../assets/slyck_project_screen.png';
import slyck_logo_bw from '../assets/slyck_logo_bw.svg';
import components_thumb from '../assets/components_thumb.png';
import pages_thumb from '../assets/pages_thumb.png';
import elements_thumb from '../assets/elements_thumb.png';
import download_app_store from '../assets/download_app_store.svg';

// components

import styled from 'styled-components';


/*************************************
* Styled components
*************************************/

const STYLED_01TWDB = styled.div`
  padding: 2rem;
  border: 1px solid #ddd;
  max-width: 48rem;
  border-radius: 1rem;
`;
const STYLED_WDSSYY = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
`;
const STYLED_CNIYPP = styled.div`
  position: relative;
  padding: 37.5% 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const STYLED_LNDEIR = styled.div`
  line-height: 1.4;
  color: #666;
`;
const STYLED_FLYVQI = styled.div`
  display: flex;
  column-gap: 1.2rem;
  margin-top: 1.6rem;
`;
const STYLED_SYC65Q = styled.div`
  font-weight: 500;
  opacity: 0.5;
`;
const STYLED_U4A16M = styled.div`
  background-color: hsl(212deg,95%,93%);
  color: var(--primary-color);
  padding: 0.4rem 0.6rem;
  border-radius: 0.5rem;
  font-weight: 500;
`;
const STYLED_ADCX5O = styled.img`
  position: absolute;
  max-width: 80%;
  max-height: 80%;
`;
const STYLED_JDTAXV = styled.button`
  background-color: #f2f2f2;
  border: none;
  border-radius: 0.8rem;
  padding: 1.2rem 1.6rem;
  font: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 0.8rem;
  font-weight: 400;
  flex-basis: 50%;
`;
const STYLED_VOHMSN = styled.button`
  background-color: var(--primary-color);
  border: none;
  border-radius: 0.8rem;
  padding: 1.2rem 1.6rem;
  color: white;
  font: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 0.8rem;
  font-weight: 400;
  flex-basis: 50%;
`;


/*************************************
* Component export
*************************************/

export default function Template(props) {
  const { price, name, img, description } = props;

  return (
    <STYLED_01TWDB data-slyck_id="01TwdBvnrVqYBQ3o0zNR">
      <STYLED_WDSSYY data-slyck_id="WdsSYYzletsUGXfbmE9s">
        <STYLED_SYC65Q data-slyck_id="SYc65qVt0Tip8WBMye62">{name}</STYLED_SYC65Q>
        {false && (
          <STYLED_U4A16M data-slyck_id="U4a16MeNfdB00QoCA7sX">{price}</STYLED_U4A16M>
        )}
      </STYLED_WDSSYY>
      <STYLED_CNIYPP data-slyck_id="cNiypp272Uaq6DZWOgxm">
        <STYLED_ADCX5O src={img} data-slyck_id="aDcx5O7AhaoGKC5KItUt" />
      </STYLED_CNIYPP>
      <STYLED_LNDEIR data-slyck_id="lNdEiRJb0JGKuxKLiL3q">{description}</STYLED_LNDEIR>
      <STYLED_FLYVQI data-slyck_id="FlyVQIT2ECFLUEoSxsyn">
        <STYLED_JDTAXV data-slyck_id="jdtaXVpYUfhEfdTHUyQU">View Demo</STYLED_JDTAXV>
        <STYLED_VOHMSN data-slyck_id="VohmSNpSxJneFeg4e28p">Coming soon</STYLED_VOHMSN>
      </STYLED_FLYVQI>
    </STYLED_01TWDB>
  );

}
