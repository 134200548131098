/*************************************
* File generated by Slyck IDE
* ContentBlock Component
*************************************/

import { useState, useEffect, useLayoutEffect, useRef, useMemo, useCallback, Fragment } from 'react';

// state management
import { useDispatch, useSelector } from 'react-redux';
//assets
import app from '../assets/app.png';
import firebase_template from '../assets/firebase_template.png';
import slyck_project_screen from '../assets/slyck_project_screen.png';
import slyck_logo_bw from '../assets/slyck_logo_bw.svg';
import components_thumb from '../assets/components_thumb.png';
import pages_thumb from '../assets/pages_thumb.png';
import elements_thumb from '../assets/elements_thumb.png';
import download_app_store from '../assets/download_app_store.svg';

// components

import styled from 'styled-components';


/*************************************
* Styled components
*************************************/

const STYLED_CR5HME = styled.div`
  display: flex;
  align-items: flex-start;
  column-gap: 2.4rem;
  width: 100%;
  max-width: 35.4rem;
@media (max-width: 768px) {
    
      max-width: none;
    }
`;
const STYLED_HZVMC2 = styled.div`
  font-size: 3.2rem;
  color: var(--primary-color);
  background-color: #f1f3f7;
  width: 6rem;
  flex-shrink: 0;
  height: 6rem;
  display: flex;
  align-items: center;
  border-radius: 1.2rem;
  justify-content: center;
`;
const STYLED_JRX4M2 = styled.div`
  font-weight: 600;
  line-height: 1.1;
  margin-bottom: 0.4rem;
`;
const STYLED_RSCKRI = styled.div`
  line-height: 1.4;
`;


/*************************************
* Component export
*************************************/

export default function ContentBlock(props) {
  const { body, title, icon } = props;

  return (
    <STYLED_CR5HME data-slyck_id="CR5hmePlrxqLrO7sAz6w">
      <STYLED_HZVMC2 data-slyck_id="HZvMc2FH2cK3dooaFa5X">{icon}</STYLED_HZVMC2>
      <div data-slyck_id="qWgATsmoC9Hrq7iEJmcV">
        <STYLED_JRX4M2 data-slyck_id="Jrx4m2iDoV1RpYKkxjFq">{title}</STYLED_JRX4M2>
        <STYLED_RSCKRI data-slyck_id="RSCkRIjEQ5xvjoYxHUbM">{body}</STYLED_RSCKRI>
      </div>
    </STYLED_CR5HME>
  );

}
