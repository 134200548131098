/*************************************
* File generated by Slyck IDE
* Root reducer
*************************************/

import { createStore, applyMiddleware, combineReducers } from 'redux';
import thunk from 'redux-thunk';

import user from './userSlice';
import main from './mainSlice';

export const rootReducer = combineReducers({ user, main });
export const store = createStore(rootReducer, applyMiddleware(thunk));

    