/*************************************
* File generated by Slyck IDE
* Footer Component
*************************************/

import { useState, useEffect, useLayoutEffect, useRef, useMemo, useCallback, Fragment } from 'react';

// state management
import { useDispatch, useSelector } from 'react-redux';
//assets
import app from '../assets/app.png';
import firebase_template from '../assets/firebase_template.png';
import slyck_project_screen from '../assets/slyck_project_screen.png';
import slyck_logo_bw from '../assets/slyck_logo_bw.svg';
import components_thumb from '../assets/components_thumb.png';
import pages_thumb from '../assets/pages_thumb.png';
import elements_thumb from '../assets/elements_thumb.png';
import download_app_store from '../assets/download_app_store.svg';

// components
import { Link } from "react-router-dom";

import styled from 'styled-components';


/*************************************
* Styled components
*************************************/

const STYLED_FOOTERCONTAINER = styled.div`
  padding: 1.6rem;
`;
const STYLED_FOOTERCONTENT = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: var(--max-width);
  margin: 0 auto;
  padding: 4rem 0;
@media (max-width: 768px) {
    
      flex-direction: column-reverse;
      row-gap: 2rem;
    }
`;
const STYLED_RIGHT = styled.div`
  display: flex;
  column-gap: 2rem;
`;


/*************************************
* Component export
*************************************/

export default function Footer(props) {

  return (
    <STYLED_FOOTERCONTAINER data-slyck_id="uvu6DPY39r74RW9OD83M">
      <STYLED_FOOTERCONTENT data-slyck_id="5YGAO5cb5gjYCQYB4i76">
        <div data-slyck_id="WOyZnrC8tvAHPhdrLlRr">
          <div data-slyck_id="y1tUBDcCDX89koTVAfC2">Copyright © 2022 Human Era Ltd.</div>
        </div>
        <STYLED_RIGHT data-slyck_id="ikFYTpeNqMdMz35FjHOJ">
          <a target={"_blank"} href={"https://us6.list-manage.com/contact-form?u=6198a1286220b12a533fff0f4&form_id=857ca5e5b4968a7c8c04e8a291297172"} data-slyck_id="0FkUTBK8MmIrIZruX41R">Get in touch</a>
          <Link to={"/privacy"} data-slyck_id="1XfD6kisMefpgkJQOvRq">Privacy</Link>
        </STYLED_RIGHT>
      </STYLED_FOOTERCONTENT>
    </STYLED_FOOTERCONTAINER>
  );

}
