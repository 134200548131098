/*************************************
* File generated by Slyck IDE
* PriceBox Component
*************************************/

import { useState, useEffect, useLayoutEffect, useRef, useMemo, useCallback, Fragment } from 'react';

// state management
import { useDispatch, useSelector } from 'react-redux';
//assets
import app from '../assets/app.png';
import firebase_template from '../assets/firebase_template.png';
import slyck_project_screen from '../assets/slyck_project_screen.png';
import slyck_logo_bw from '../assets/slyck_logo_bw.svg';
import components_thumb from '../assets/components_thumb.png';
import pages_thumb from '../assets/pages_thumb.png';
import elements_thumb from '../assets/elements_thumb.png';
import download_app_store from '../assets/download_app_store.svg';

// components

import styled from 'styled-components';


/*************************************
* Styled components
*************************************/

const STYLED_PRICEBOXCONTENT = styled.div`
  border: 1px solid #ddd;
  padding: 1.6rem;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
const STYLED_RGHDEQ = styled.button`
  background: blue;
  border: none;
  margin: 1.2rem 0 0;
  appearance: none;
  padding: 1.2rem 2rem;
  font-size: 2rem;
  border-radius: 0.8rem;
  width: 100%;
`;
const STYLED_PRICEBOXPRICE = styled.div`
  font-size: 5.8rem;
  font-weight: 500;
`;
const STYLED_PRICEBOXFEATURES = styled.div`
  margin-top: 2rem;
`;
const STYLED_PRICEBOXFEATURE = styled.div`
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='20px' height='20px' xmlns='http://www.w3.org/2000/svg' fill='%234f95fa'%3E %3Cpath fill-rule='evenodd' d='M10,2 C14.418278,2 18,5.581722 18,10 C18,14.418278 14.418278,18 10,18 C5.581722,18 2,14.418278 2,10 C2,5.581722 5.581722,2 10,2 Z M14.2071068,7.29289322 C13.8165825,6.90236893 13.1834175,6.90236893 12.7928932,7.29289322 L12.7928932,7.29289322 L8.5,11.585 L7.20710678,10.2928932 L7.11289944,10.2097046 C6.72060824,9.90467972 6.15337718,9.93240926 5.79289322,10.2928932 C5.40236893,10.6834175 5.40236893,11.3165825 5.79289322,11.7071068 L5.79289322,11.7071068 L7.79289322,13.7071068 L7.88710056,13.7902954 C8.27939176,14.0953203 8.84662282,14.0675907 9.20710678,13.7071068 L9.20710678,13.7071068 L14.2071068,8.70710678 L14.2902954,8.61289944 C14.5953203,8.22060824 14.5675907,7.65337718 14.2071068,7.29289322 Z'/%3E %3C/svg%3E");
  background-repeat: no-repeat;
  padding-left: 3rem;
  margin: 1rem 0;
`;


/*************************************
* Component export
*************************************/

export default function PriceBox(props) {
  const { btnText = ((name) => `Choose ${name}`)(props.name), features = [], btnBackground = '#f4f4f4', price, btnColor = 'black', onChoose = () => {}, name, cycle } = props;
  
  /**
   * Handles the onClick event for the undefined element.
   * @param {Object} event - event object passed from browser
   */
  const onClickrgHDEQ = (event) => {
    
    onChoose(name)
  }

  return (
    <STYLED_PRICEBOXCONTENT data-slyck_id="8rsO4yc9XhwBwzuOF72W">
      <div data-slyck_id="7R2Q927kyB5hHmQGcJZi">
        <div data-slyck_id="14219jhHZHQsCmwhLJ72">{name}</div>
        <STYLED_PRICEBOXPRICE data-slyck_id="tE8Q0Ve3FrHdtOtXY8zO">{price?.endsWith('.00') ? price.split('.00')[0] : price}</STYLED_PRICEBOXPRICE>
        {!!cycle && (
          <div data-slyck_id="CokyidXR4SYbtWq9fg5Z">{cycle}</div>
        )}
        {features.map((priceBoxFeature, index) => (
          <STYLED_PRICEBOXFEATURES key={priceBoxFeature} data-slyck_id="5HwigDOP6O1wmSmOelM3">
            <STYLED_PRICEBOXFEATURE data-slyck_id="kWiDzmbjxAjXPZsFN7ZT">{priceBoxFeature}</STYLED_PRICEBOXFEATURE>
          </STYLED_PRICEBOXFEATURES>
        ))}
      </div>
      <STYLED_RGHDEQ style={{ background: btnBackground, color: btnColor }} data-slyck_id="rgHDEQ7icC9E9nxk0RmE" onClick={onClickrgHDEQ}>{btnText}</STYLED_RGHDEQ>
    </STYLED_PRICEBOXCONTENT>
  );

}
