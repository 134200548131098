/*************************************
* File generated by Slyck IDE
* Templates Component
*************************************/

import { useState, useEffect, useLayoutEffect, useRef, useMemo, useCallback, Fragment } from 'react';

// state management
import { useDispatch, useSelector } from 'react-redux';
import { getScrollTop, setScrollTop as _setScrollTop, setScrollDirection as _setScrollDirection } from '../mainSlice';
//assets
import app from '../assets/app.png';
import firebase_template from '../assets/firebase_template.png';
import slyck_project_screen from '../assets/slyck_project_screen.png';
import slyck_logo_bw from '../assets/slyck_logo_bw.svg';
import components_thumb from '../assets/components_thumb.png';
import pages_thumb from '../assets/pages_thumb.png';
import elements_thumb from '../assets/elements_thumb.png';
import download_app_store from '../assets/download_app_store.svg';

// components
import Header from "../components/Header";
import Footer from "../components/Footer";
import Template from "../components/Template";

import styled from 'styled-components';


/*************************************
* Styled components
*************************************/

const STYLED_2LHMKK = styled.div`
  height: 100%;
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
`;
const STYLED_KI8NWD = styled.div`
  padding: 1.6rem;
`;
const STYLED_5MKGOL = styled.div`
  max-width: var(--max-width);
  margin: 0 auto 4rem;
  text-align: center;
@media (max-width: 768px) {
    
      text-align: left;
    }
`;
const STYLED_HFC82H = styled.div`
  display: flex;
  max-width: var(--max-width);
  margin: 0 auto;
  column-gap: 4rem;
  justify-content: center;
  flex-wrap: wrap;
  row-gap: 2rem;
`;
const STYLED_3IOK57 = styled.div`
  font-size: var(--sub-heading-size);
`;


/*************************************
* Component export
*************************************/

export default function Templates(props) {

  // actions
  const dispatch = useDispatch();
  const setScrollTop = useCallback((payload) => dispatch({type: 'setScrollTop', payload}), [dispatch]);
  const setScrollDirection = useCallback((payload) => dispatch({type: 'setScrollDirection', payload}), [dispatch]);

  // variables
  const scrollTop = useSelector((state) => getScrollTop(state, props));

  // side effects
  useEffect(() => {
    setScrollTop(0);
  }, [setScrollTop]);

  useEffect(() => {
    window.gtag('set', 'page_path', '/templates');
    window.gtag('event', 'page_view');
  }, []);
  
  /**
   * Handles the onScroll event for the undefined element.
   * @param {Object} event - event object passed from browser
   */
  const onScroll2lhMKK = (event) => {
    
    setScrollTop(event.target.scrollTop);
    if (event.target.scrollTop > scrollTop) {
      setScrollDirection('down');
    } else {
      setScrollDirection('up');
    }
  }

  return (
    <STYLED_2LHMKK onScroll={onScroll2lhMKK} className={scrollTop <= 0 || !scrollTop ? 'scrolltop' : ''} data-slyck_id="2lhMKKhByoHB2CtYE9sV">
      <Header data-slyck_id="CSazhgNVwdIVImPtbv8z" />
      <STYLED_KI8NWD data-slyck_id="Ki8nWdTt64M5oeOj40uh">
        <STYLED_5MKGOL data-slyck_id="5mkGOluCTcNAgXMDUZ3Y">
          <h1 data-slyck_id="gYENkMUgR1n0deCVq01Q">Kickstart your next app.</h1>
          <STYLED_3IOK57 data-slyck_id="3ioK57auzszNprjBd9NB">Slyck project templates help you build production-ready apps in no time at all.</STYLED_3IOK57>
        </STYLED_5MKGOL>
        <STYLED_HFC82H data-slyck_id="hfC82HpXCoNryTjRE7ov">
          <Template name={"SaaS web app"} price={"£750"} img={slyck_project_screen} description={"The SaaS web app template comes with a complete user registration flow that includes user settings and profiles, as well as stripe payment integration."} data-slyck_id="EDf6DeaQbEKF6Ie9J3rS" />
          <Template img={app} price={"£750"} name={"iOS/Android app"} description={"The iOS/Android app template gives you the perfect start to build high performance mobile apps with native styles using the Capacitor JS framework."} data-slyck_id="fp3I83KyRmvJ7LBR0adq" />
        </STYLED_HFC82H>
      </STYLED_KI8NWD>
      <Footer data-slyck_id="3QjYjhYsll32Jtbd6G9h" />
    </STYLED_2LHMKK>
  );

}
