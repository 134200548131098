import { Provider } from 'react-redux';
import { store } from './rootReducer';
import { BrowserRouter } from 'react-router-dom';

export default function AppProviders({ children }) {
  return (
    <Provider store={store}>
      <BrowserRouter>
        {children}
      </BrowserRouter>
    </Provider>
  )
}