/*************************************
* File generated by Slyck IDE
* Index JS
*************************************/

import React from 'react';
import { createRoot } from 'react-dom/client';

// firebase imports
import { initializeApp } from 'firebase/app';
import { initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check';

import AppProviders from './AppProviders';
import Story from './.slyck/Story';
import App from './App';

import './index.css';
import * as serviceWorker from './serviceWorker';

const firebaseConfig = {
  "apiKey": "AIzaSyDMSzDWBYYSpye0gKZDwSVepkEcg7muGAQ",
  "authDomain": "auth.slyck.app",
  "databaseURL": "https://slyck-ffeb6.firebaseio.com",
  "projectId": "slyck-ffeb6",
  "storageBucket": "slyck-ffeb6.appspot.com",
  "messagingSenderId": "768159297860",
  "appId": "1:768159297860:web:32c8da5f92e1b1a77d6711",
  "measurementId": "G-Y0783X7QLC"
};

//Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);

initializeAppCheck(firebaseApp, {
  provider: new ReCaptchaV3Provider('6Ldev9cfAAAAAG2k6Is41f-a6yTge3q16DK3ooPf'),
  isTokenAutoRefreshEnabled: true
});

const root = createRoot(document.getElementById('root'));
if (process.env.REACT_APP_VIEW === 'storybook') {
  root.render(<AppProviders><Story /></AppProviders>) 
} else {
  root.render(<AppProviders><App /></AppProviders>)
};

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
    