/*************************************
* File generated by Slyck IDE
* Upgrade Component
*************************************/

import { useState, useEffect, useLayoutEffect, useRef, useMemo, useCallback, Fragment } from 'react';

// state management
import { useDispatch, useSelector } from 'react-redux';
import { getUser, updateUser as _updateUser } from '../userSlice';
import { getProPlanPrice, setScrollTop as _setScrollTop } from '../mainSlice';
//assets
import app from '../assets/app.png';
import firebase_template from '../assets/firebase_template.png';
import slyck_project_screen from '../assets/slyck_project_screen.png';
import slyck_logo_bw from '../assets/slyck_logo_bw.svg';
import components_thumb from '../assets/components_thumb.png';
import pages_thumb from '../assets/pages_thumb.png';
import elements_thumb from '../assets/elements_thumb.png';
import download_app_store from '../assets/download_app_store.svg';

// components
import { signInWithCredential, getAuth, GithubAuthProvider, signInWithPopup, signInWithRedirect, fetchSignInMethodsForEmail, sendSignInLinkToEmail } from "firebase/auth";
import { getFunctions, httpsCallable } from "firebase/functions";
import Header from "../components/Header";
import { FaGithub, FaPlus, FaMinus } from "react-icons/fa";
import PriceBox from "../components/PriceBox";

import styled from 'styled-components';


/*************************************
* Styled components
*************************************/

const STYLED_TRAYMN = styled.div`
  padding: 1.6rem;
  height: calc(100vh - 6.4rem);
  display: flex;
  align-items: center;
  margin: 0 auto;
`;
const STYLED_5E2DRU = styled.div`
  max-width: var(--max-width);
  margin: 0 auto;
  height: 100%;
  display: flex;
  align-items: center;
  width: 100%;
`;
const STYLED_F9RCT9 = styled.div`
  max-width: 420px;
  margin: 0 auto;
  text-align: center;
`;
const STYLED_ZW9OAE = styled.div`
  max-width: 680px;
  margin: 0 auto;
  text-align: center;
`;
const STYLED_LDV1AS = styled.div`
  text-align: center;
  max-width: 440px;
  margin: 0 auto;
`;
const STYLED_WJMIN4 = styled.div`
  display: flex;
  column-gap: 1.2rem;
  width: 100%;
  flex-direction: column;
  align-items: center;
`;
const STYLED_P2OXEP = styled.div`
  .show-checkout & {
    display: grid;
  }
  
  display: none;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 1.6rem;
  height: 100%;
  width: 100%;
  align-items: center;
  max-width: 850px;
  margin: 0 auto;
`;
const STYLED_6QOURL = styled.div`
  font-size: var(--sub-heading-size);
`;
const STYLED_6ET4VQ = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 0.8rem;
  margin-top: 2.4rem;
`;
const STYLED_J03UXM = styled.button`
  background-color: var(--primary-color);
  border: none;
  border-radius: 0.8rem;
  padding: 1.2rem 1.6rem;
  color: white;
  font: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 0.8rem;
  font-weight: 400;
`;
const STYLED_GMXAJ4 = styled.div`
  display: flex;
  align-items: center;
  column-gap: 1.2rem;
  color: #888;
  margin: 0.8rem 0;

  &::before {
    content: '';
    height: 1px;
    background-color: #ddd;
    width: 100%;
  }
  
  &::after {
    content: '';
    height: 1px;
    background-color: #ddd;
    width: 100%;
  }
`;
const STYLED_LDKNHQ = styled.input`
  border: 1px solid #ddd;
  border-radius: 0.8rem;
  padding: 1rem 1.2rem;
  font: inherit;

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px #167ffe;
    border-color: #167ffe;
  }
`;
const STYLED_V9FGSI = styled.button`
  background-color: var(--primary-color);
  border: none;
  border-radius: 0.8rem;
  padding: 1.2rem 1.6rem;
  color: white;
  font: inherit;
  font-weight: 400;
`;
const STYLED_QHZFI4 = styled.div`
  max-width: 14rem;
  margin: 0 auto;
`;
const STYLED_KU5OSX = styled.div`
  font-size: var(--sub-heading-size);
`;
const STYLED_NYGGAH = styled.div`
  margin-top: 2rem;
  display: flex;
  column-gap: 0.8rem;
  align-items: center;
  justify-content: center;
`;
const STYLED_ZRUOJG = styled.button`
  background: none;
  appearance: none;
  border: none;
  color: var(--primary-color);
  padding: 0;
  font: inherit;
`;
const STYLED_DWRBCZ = styled.div`
  font-size: var(--sub-heading-size);
  margin-bottom: 2rem;
`;
const STYLED_FACVLM = styled.a`
  display: block;
  margin-top: 1.6rem;
`;
const STYLED_QRLWVL = styled.h1`
  max-width: 440px;
  text-align: center;
`;
const STYLED_CHXHUA = styled.div`
  display: flex;
  column-gap: 1.2rem;
  justify-content: center;
  width: 100%;
  margin-top: 2rem;
`;
const STYLED_2EUYIP = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const STYLED_E2BONW = styled.div`
  & iframe {
    position: absolute !important;
  }
  
  height: 570px;
  position: relative;
`;
const STYLED_QMTUYG = styled.label`
  margin-top: 2rem;
  font-size: var(--sub-heading-size);
`;
const STYLED_DEGR11 = styled.div`
  display: flex;
  column-gap: 0.4rem;
  margin: 2rem 0 2rem;
`;
const STYLED_WS3YHZ = styled.div`
  column-gap: 1.2rem;
  align-items: baseline;
`;
const STYLED_RIMXTD = styled.button`
  background-color: var(--primary-color);
  border: none;
  border-radius: 0.8rem;
  padding: 1.2rem 1.6rem;
  color: white;
  font: inherit;
  font-weight: 400;
  margin-top: 1.6rem;
`;
const STYLED_BPQQVH = styled.input`
  &::-webkit-inner-spin-button, &::-webkit-outer-spin-button {
    appearance: none;
  }
  
  appearance: none;
  border: 1px solid #ddd;
  height: 3.6rem;
  font-size: inherit;
  padding: 0.4rem 0.8rem;
  width: 8rem;
`;
const STYLED_TQTXCN = styled.button`
  height: 3.6rem;
  width: 3.6rem;
  background: none;
  appearance: none;
  border: 1px solid #ddd;
  margin: 0;
`;
const STYLED_YPLEZ3 = styled.button`
  height: 3.6rem;
  width: 3.6rem;
  background: none;
  appearance: none;
  border: 1px solid #ddd;
  margin: 0;
`;
const STYLED_2QELCY = styled.div`
  font-size: 5.8rem;
  font-weight: 500;
`;
const STYLED_KFKJCT = styled.div`
  font-size: var(--sub-heading-size);
`;


/*************************************
* Component export
*************************************/

export default function Upgrade(props) {

  // actions
  const dispatch = useDispatch();
  const setScrollTop = useCallback((payload) => dispatch({type: 'setScrollTop', payload}), [dispatch]);
  const updateUser = useCallback((payload) => dispatch({type: 'updateUser', payload}), [dispatch]);

  // variables
  const proPlanPrice = useSelector((state) => getProPlanPrice(state, props));
  const [quantity,setQuantity] = useState(1);
  const [fetchingPlanData,setFetchingPlanData] = useState(true);
  const [showCheckout,setShowCheckout] = useState(null);
  const [currentPlan,setCurrentPlan] = useState(null);
  const [checkoutPrice,setCheckoutPrice] = useState(null);
  const [waitForEmail,setWaitForEmail] = useState(null);
  const [email,setEmail] = useState('');
  const user = useSelector((state) => getUser(state, props));

  // side effects
  useEffect(() => {
    setScrollTop(0);
  }, [setScrollTop]);

  useEffect(() => {
    const queryDict = {};
    const githubState = localStorage.getItem('authState');
    window.location.search.substr(1).split("&").forEach(function(item) {queryDict[item.split("=")[0]] = item.split("=")[1]});
    if (queryDict.access_token && queryDict.state === githubState) {
      const auth = getAuth();
      const credential = GithubAuthProvider.credential(queryDict.access_token);
      signInWithCredential(auth, credential)
        .then(() => {
          localStorage.removeItem('authState');
          window.location.href = 'https://slyck.app/upgrade';
        })
        .catch((error) => {
          localStorage.removeItem('authState');
          if (error && error.toString().includes('auth/account-exists-with-different-credential')) {
            alert('The account you are trying to sign in with exists with a different sign in method. Try signing in by email');
          }
        });
    }
  }, []);

  useEffect(() => {
    const { Paddle } = window;
    Paddle.Product.Prices(741066, quantity, (data) => {
      setCheckoutPrice(data.price.gross);
    });
  }, [quantity]);

  useEffect(() => {
    // if (showCheckout) {
    //   const { Paddle } = window;
    //   const passthrough = JSON.stringify({
    //     user_id: user.uid
    //   });
    //   Paddle.Checkout.open({
    //     product: 741066,
    //     email: user.email,
    //     method: 'inline',
    //     frameTarget: 'paddle-checkout',
    //     allowQuantity: true,
    //     quantity,
    //     passthrough
    //   });
    // }
  }, [quantity, user, showCheckout]);

  useEffect(() => {
    const fetchData = async () => {
      if (user.authState === 'SIGNED_IN') {
        const functions = getFunctions();
        const getUserPlan = httpsCallable(functions, 'getUserPlan');
        const response = await getUserPlan();
    
        if (response?.data && response?.data?.id && response.data.id !== currentPlan?.id) {
          setCurrentPlan(response.data);
        }
        setFetchingPlanData(false)
      }
    }
    fetchData();
  }, [user, currentPlan]);

  useEffect(() => {
    window.gtag('set', 'page_path', '/upgrade');
    window.gtag('event', 'page_view');
  }, []);
  
  /**
   * Handles the onClick event for the undefined element.
   * @param {Object} event - event object passed from browser
   */
  const onClickJ03Uxm = (event) => {
    
    const githubState = Math.random().toString(36).substr(2, 11);
    localStorage.setItem('authState',githubState);
    window.location.href = `https://github.com/login/oauth/authorize?client_id=9c60004ef4ed5994bfb6&context_uri=https%3A%2F%2Fslyck.app&redirect_uri=https%3A%2F%2Fslyck.app%2Fauth&response_type=code&scope=user%3Aemail&state=${githubState}`
    // const auth = getAuth();
    // console.log(auth.currentUser)
    // const provider = new GithubAuthProvider();
    // provider.setCustomParameters({
    //   'redirect_uri': 'https://slyck.app/auth'
    // });
    // signInWithRedirect(auth, provider)
    //   .then((result) => {
    //     // This gives you a GitHub Access Token. You can use it to access the GitHub API.
    //     const credential = GithubAuthProvider.credentialFromResult(result);
    //     const token = credential.accessToken;
    
    //     // The signed-in user info.
    //     const user = result.user;
    //     console.log(user)
    //     // ...
    //   }).catch((error) => {
    //     // Handle Errors here.
    //     const errorCode = error.code;
    //     const errorMessage = error.message;
    //     // The email of the user's account used.
    //     const email = error.email;
    //     // The AuthCredential type that was used.
    //     const credential = GithubAuthProvider.credentialFromError(error);
    //     // ...
    //   });
  }
  /**
   * Handles the onChange event for the undefined element.
   * @param {Object} event - event object passed from browser
   */
  const onChangelDKnhQ = (event) => {
    
    setEmail(event.target.value)
  }
  /**
   * Handles the onClick event for the undefined element.
   * @param {Object} event - event object passed from browser
   */
  const onClickv9fGSI = async (event) => {
    
    const auth = getAuth();
    const actionCodeSettings = {
      // URL you want to redirect back to. The domain (www.example.com) for this
      // URL must be in the authorized domains list in the Firebase Console.
      url: 'https://slyck.app/upgrade',
      // This must be true.
      handleCodeInApp: true
    };
    
    try {
      const signInMethods = await fetchSignInMethodsForEmail(auth, email);
    
      if (signInMethods.length && signInMethods[0] === 'github.com') {
        throw new Error('Email address in use with Github account. Sign in using Github instead.');
      } else {
        const userCredential = await sendSignInLinkToEmail(auth, email, actionCodeSettings);
        window.localStorage.setItem('emailForSignIn', email);
        setWaitForEmail(true);
      }
    } catch (error) {
    
      let message = 'Error';
      let detail = error.message;
    
      if (error.message.includes('auth/invalid-email')) {
        message = 'Invalid email address'
        detail = 'The email address you entered is invalid. Enter a valid email';
      }
    
      alert(detail);
    }
  }
  /**
   * Handles the onClick event for the undefined element.
   * @param {Object} event - event object passed from browser
   */
  const onClickzRuojg = (event) => {
    
    setWaitForEmail(false)
  }
  /**
   * Handles the onChange event for the undefined element.
   * @param {Object} event - event object passed from browser
   */
  const onChangeBpqqvh = (event) => {
    
    const value = event.target.value;
    const quantity = parseInt(value);
    if (quantity) {
      setQuantity(quantity)
    }
  }
  /**
   * Handles the onClick event for the undefined element.
   * @param {Object} event - event object passed from browser
   */
  const onClickTQtXcn = (event) => {
    
    setQuantity(quantity + 1);
  }
  /**
   * Handles the onClick event for the undefined element.
   * @param {Object} event - event object passed from browser
   */
  const onClickyPlEz3 = (event) => {
    
    if (quantity > 1) {
      setQuantity(quantity-1);
    }
  }

  return (
    <div data-slyck_id="iUIqx7DgNxJqavtni875">
      <Header data-slyck_id="BUN9dslOeNh5tQhiwa7B" />
      <STYLED_TRAYMN className={`${showCheckout ? 'show-checkout' : ''}`} data-slyck_id="trAyMNQW3vOtXyLFowel">
        <STYLED_5E2DRU data-slyck_id="5e2dRua1y0WOYy4HH7Yo">
          {user.authState === 'SIGNED_OUT' && !waitForEmail && (
            <STYLED_F9RCT9 data-slyck_id="F9RCt930iIk22l4PJXPZ">
              <h1 data-slyck_id="g71lpM7LUb1SVtTiM0HR">Upgrade to Slyck Pro</h1>
              <STYLED_6QOURL data-slyck_id="6qOURLzSCSVa6DpLP7gt">Sign in to upgrade your account</STYLED_6QOURL>
              <STYLED_6ET4VQ data-slyck_id="6eT4vQhF5waZc7Im8IsH">
                <STYLED_J03UXM onClick={onClickJ03Uxm} data-slyck_id="J03UxmsjgmvDN7QZQFx4">
                  <FaGithub data-slyck_id="eDgnsv47iuujFv58NCJV" />
                  <span data-slyck_id="MUnSDQnYSaxb0KQQ6ouR">Sign in with Github</span>
                </STYLED_J03UXM>
                <STYLED_GMXAJ4 data-slyck_id="gMxAj4GgIpWGmpU52gvk">or</STYLED_GMXAJ4>
                <STYLED_LDKNHQ placeholder={'Email address'} type={"email"} value={email} data-slyck_id="lDKnhQ4Qp0lpyPtMUxnX" onChange={onChangelDKnhQ} />
                <STYLED_V9FGSI data-slyck_id="v9fGSIf6TopTpSnZ0yuk" onClick={onClickv9fGSI}>Sign in with email</STYLED_V9FGSI>
              </STYLED_6ET4VQ>
            </STYLED_F9RCT9>
          )}
          {waitForEmail && (
            <STYLED_ZW9OAE data-slyck_id="zw9oaelTGm9v0PaR0V5m">
              <STYLED_QHZFI4 data-slyck_id="qHzFi4NZXimps1RE5PJV">
                <svg xmlns="http://www.w3.org/2000/svg" version="1.1" x="0px" y="0px" viewBox="0 0 59 59" enable-background="new 0 0 59 59" >
                  <g>
                    <path d="M47,30c-6.617,0-12,5.383-12,12s5.383,12,12,12s12-5.383,12-12S53.617,30,47,30z M47,52c-5.514,0-10-4.486-10-10   s4.486-10,10-10s10,4.486,10,10S52.514,52,47,52z"/>
                    <path d="M53,41h-5v-5c0-0.553-0.447-1-1-1s-1,0.447-1,1v6c0,0.553,0.447,1,1,1h6c0.553,0,1-0.447,1-1S53.553,41,53,41z"/>
                    <path d="M25.369,28.853c1.002,0.894,2.317,1.341,3.633,1.341c1.313,0,2.626-0.446,3.625-1.337L56,8.218V29c0,0.553,0.447,1,1,1   s1-0.447,1-1V6c0-0.008-0.004-0.015-0.005-0.023c-0.002-0.111-0.019-0.222-0.06-0.33c-0.003-0.007-0.008-0.012-0.01-0.019   c-0.011-0.027-0.029-0.049-0.042-0.075c-0.041-0.081-0.089-0.155-0.148-0.22c-0.026-0.029-0.054-0.052-0.083-0.078   c-0.062-0.054-0.129-0.099-0.202-0.136c-0.033-0.017-0.063-0.035-0.098-0.048C57.24,5.029,57.124,5,57,5H1   C0.876,5,0.76,5.029,0.649,5.071C0.614,5.084,0.584,5.102,0.551,5.119C0.478,5.156,0.411,5.201,0.348,5.255   C0.319,5.281,0.292,5.304,0.266,5.333c-0.06,0.065-0.108,0.14-0.149,0.221C0.104,5.579,0.086,5.601,0.075,5.628   C0.072,5.634,0.067,5.64,0.064,5.646c-0.041,0.108-0.057,0.219-0.06,0.33C0.004,5.985,0,5.992,0,6v39   c0,0.003,0.002,0.006,0.002,0.009c0.001,0.107,0.017,0.214,0.053,0.319c0.004,0.012,0.013,0.022,0.018,0.034   c0.013,0.034,0.034,0.063,0.051,0.095c0.039,0.074,0.082,0.142,0.137,0.203c0.028,0.031,0.056,0.058,0.088,0.085   c0.06,0.053,0.126,0.096,0.197,0.133c0.034,0.018,0.066,0.037,0.102,0.051C0.759,45.971,0.876,46,1,46h30c0.553,0,1-0.447,1-1   s-0.447-1-1-1H3.879c4.548-3.604,14.801-11.781,20.069-16.403L25.369,28.853z M54.356,7L31.299,27.36   c-1.267,1.132-3.331,1.132-4.602-0.004l-1.979-1.747c-0.005-0.007-0.007-0.015-0.012-0.021c-0.022-0.025-0.053-0.035-0.077-0.058   L3.644,7H54.356z M2,42.937V8.218l20.437,18.046C16.881,31.117,6.086,39.704,2,42.937z"/>
                  </g>
                </svg>
              </STYLED_QHZFI4>
              <h1 data-slyck_id="M9BL5fPmEenWha3dt8qb">Check your inbox</h1>
              <STYLED_KU5OSX data-slyck_id="ku5OSxHGmPfaJiwVns20">We sent you an email - click on the link inside to sign in.</STYLED_KU5OSX>
              <STYLED_NYGGAH data-slyck_id="nyGGAh6QvkARkuShzk3k">
                <div data-slyck_id="zs4673xH3BqK3oQpzmmm">Didn‘t receive an email?</div>
                <STYLED_ZRUOJG data-slyck_id="zRuojgfce3V7OdWpofX2" onClick={onClickzRuojg}>Try again</STYLED_ZRUOJG>
              </STYLED_NYGGAH>
            </STYLED_ZW9OAE>
          )}
          {currentPlan?.subscription_plan_id === '741066' && user.authState === 'SIGNED_IN' && (
            <STYLED_LDV1AS data-slyck_id="LDV1asoXduezNkFQfdmW">
              <h1 data-slyck_id="2Gxv0t9hZ6nUxwfLodKZ">Upgrade successful</h1>
              <STYLED_DWRBCZ data-slyck_id="DwrBczZ2WBpqamGUROTD">You‘re now subscribed to Slyck Pro.</STYLED_DWRBCZ>
              <a href={currentPlan?.update_url} data-slyck_id="VbD0eiofnHWARUDNK6Oa">Update payment method</a>
              <STYLED_FACVLM href={currentPlan?.cancel_url} data-slyck_id="FaCvlMCyxSMdRjvOhi0X">Cancel your subscription</STYLED_FACVLM>
            </STYLED_LDV1AS>
          )}
          {!showCheckout && user.authState === 'SIGNED_IN' && !fetchingPlanData && currentPlan?.subscription_plan_id !== '741066' && (
            <STYLED_WJMIN4 data-slyck_id="WJmiN4HAL6MJF00OwSzI">
              <STYLED_QRLWVL data-slyck_id="qrLWVLWlk21kq6VwQ8jG">Upgrade your subscription</STYLED_QRLWVL>
              <STYLED_CHXHUA data-slyck_id="cHxHUADdXDRBMbFNZ5a6">
                <PriceBox name={"Starter"} price={"Free"} cycle={"forever"} btnText={"Current plan"} data-slyck_id="5SNnGUHboavv9uBqCozm" />
                <PriceBox btnText={"Upgrade now"} price={proPlanPrice} name={"Pro"} onChoose={() => {
  const { Paddle } = window;
  const passthrough = JSON.stringify({
    user_id: user.uid
  });
  Paddle.Checkout.open({
    product: 741066,
    email: user.email,
    // method: 'inline',
    frameTarget: 'paddle-checkout',
    allowQuantity: true,
    disableLogout: true,
    passthrough
  });
  // setShowCheckout(true);
}} btnBackground={"var(--primary-color)"} btnColor={"white"} cycle={"per user / month"} data-slyck_id="4FuYo8h4EorDMN3Fjvq0" />
              </STYLED_CHXHUA>
            </STYLED_WJMIN4>
          )}
          <div className={"paddle-checkout"} data-slyck_id="zPpbP682bNMebca3F6Oj" />
          {showCheckout && user.authState === 'SIGNED_IN' && (
            <STYLED_P2OXEP data-slyck_id="p2oxeP0SgnHCN4bnwzSs">
              {showCheckout && user.authState === 'SIGNED_IN' && (
                <STYLED_2EUYIP data-slyck_id="2euyIPuy9WnCZlALkoTC">
                  <h1 data-slyck_id="MRWA4kWJYPjUx3iW7Z5Z">Slyck Pro</h1>
                  <STYLED_QMTUYG htmlFor={"number-of-users"} data-slyck_id="qmTuyGwgO2dET7fb2ayD">Users</STYLED_QMTUYG>
                  <STYLED_DEGR11 data-slyck_id="dEGR11PGLkVaqxiyJJad">
                    <STYLED_BPQQVH id={"number-of-users"} min={1} type={"number"} value={quantity} data-slyck_id="BpqqvhCiWLePKCMQraFq" onChange={onChangeBpqqvh} />
                    <STYLED_TQTXCN onClick={onClickTQtXcn} data-slyck_id="TQtXcnV1CGLjFxGdWV5g">
                      <FaPlus data-slyck_id="TZ0ePHvR1MFD08ROzCGn" />
                    </STYLED_TQTXCN>
                    <STYLED_YPLEZ3 onClick={onClickyPlEz3} data-slyck_id="yPlEz3CNw3dCMMSwJ3lE">
                      <FaMinus data-slyck_id="zMm7gCC9PAQv83xs6uY0" />
                    </STYLED_YPLEZ3>
                  </STYLED_DEGR11>
                  <STYLED_WS3YHZ data-slyck_id="ws3YHzDQzI0KL2D3j8EW">
                    <STYLED_2QELCY data-slyck_id="2QELcyL7lUq8lowf3YM1">{checkoutPrice?.includes('.00') ? checkoutPrice.split('.00')[0] : checkoutPrice}</STYLED_2QELCY>
                    <STYLED_KFKJCT data-slyck_id="KfKJcTwvowdsyoQDSA6o">per month</STYLED_KFKJCT>
                  </STYLED_WS3YHZ>
                  {false && (
                    <STYLED_RIMXTD data-slyck_id="riMxTDYY0FZCmZl6jTaq">Continue</STYLED_RIMXTD>
                  )}
                </STYLED_2EUYIP>
              )}
              <STYLED_E2BONW className={"paddle-checkout"} data-slyck_id="E2BOnWTEZuQQp6POe7ho" />
            </STYLED_P2OXEP>
          )}
        </STYLED_5E2DRU>
      </STYLED_TRAYMN>
    </div>
  );

}
