/*************************************
* File generated by Slyck IDE
* main slice
*************************************/

import { createSlice, createAction } from '@reduxjs/toolkit';


// actions
export const setScrollDirection = createAction('setScrollDirection');
export const showConsentMessage = createAction('showConsentMessage');
export const setScrollTop = createAction('setScrollTop');
export const hideConsentMessage = createAction('hideConsentMessage');
export const setPlanPrice = createAction('setPlanPrice');

const slice = createSlice({
  name: 'main',initialState: {
    scrollTop: 0
  },
  extraReducers: {
    [setScrollDirection]: (state, action) => {
      const { payload } = action;
      state.scrollDirection = payload;
    },
    [showConsentMessage]: (state, action) => {
      state.showConsentMessage = true;
    },
    [setScrollTop]: (state, action) => {
      const { payload } = action;
      state.scrollTop = payload;
    },
    [hideConsentMessage]: (state, action) => {
      state.showConsentMessage = false;
    },
    [setPlanPrice]: (state, action) => {
      const { payload } = action;
      const { plan, price } = payload;
      state['plan_'+plan] = price;
    }
  }
});

export default slice.reducer;

// selectors
export const showCookiesDialog = (state, props) => {
  return state.main.showConsentMessage;
};
export const getTeamsPlanPrice = (state, props) => {
  return state.main.plan_teams;
};
export const getScrollDirection = (state, props) => {
  return state.main.scrollDirection;
};
export const getScrollTop = (state, props) => {
  return state.main.scrollTop;
};
export const getProPlanPrice = (state, props) => {
  return state.main.plan_pro;
};
    