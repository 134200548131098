/*************************************
* File generated by Slyck IDE
* RainbowHeading Component
*************************************/

import { useState, useEffect, useLayoutEffect, useRef, useMemo, useCallback, Fragment } from 'react';

// state management
import { useDispatch, useSelector } from 'react-redux';
import { getScrollTop } from '../mainSlice';
//assets
import app from '../assets/app.png';
import firebase_template from '../assets/firebase_template.png';
import slyck_project_screen from '../assets/slyck_project_screen.png';
import slyck_logo_bw from '../assets/slyck_logo_bw.svg';
import components_thumb from '../assets/components_thumb.png';
import pages_thumb from '../assets/pages_thumb.png';
import elements_thumb from '../assets/elements_thumb.png';
import download_app_store from '../assets/download_app_store.svg';

// components

import styled from 'styled-components';


/*************************************
* Styled components
*************************************/

const STYLED_RAINBOWHEADINGCONTAINER = styled.div`
  position: sticky;
  top: -4rem;
  background-color: white;
  z-index: -1;
`;
const STYLED_RAINBOWHEADING = styled.h1`
  font-size: 14rem;
  font-weight: 800;
  color: hsl(322deg 76% 74%);
  max-width: 100rem;
  background: -webkit-linear-gradient(0deg,hsl(189deg 70% 60%),hsl(215deg 100% 69%),hsl(260deg 80% 75%), hsl(322deg 76% 74%) 85%);
  -webkit-text-fill-color: transparent;
  line-height: 1.1;
`;


/*************************************
* Component export
*************************************/

export default function RainbowHeading(props) {
  const { text } = props;

  // variables
  const [opacity,setOpacity] = useState(null);
  const [scale,setScale] = useState(null);
  const scrollTop = useSelector((state) => getScrollTop(state, props));
  const rainbowHeadingContainer = useRef();
  const [offsetTop,setOffsetTop] = useState(null);
  const [blur,setBlur] = useState(null);
  const containerStyle = useMemo(() => {
    const style = {
      transform: opacity <= 0 ? '' : `scale(${scale})`,
      filter: opacity <= 0 ? '' : `blur(${blur}px)`,
      opacity
    };
  
    return style;
  }, [opacity, scale, blur]);

  // element refs
  const RainbowHeadingContainer = useRef(null);

  // side effects
  useEffect(() => {
    setOffsetTop(rainbowHeadingContainer.current.offsetTop + 270);
    
    window.addEventListener('resize', () => {
      setOffsetTop(rainbowHeadingContainer.current.offsetTop + 270);
    }, true)
  }, [RainbowHeadingContainer]);

  useEffect(() => {
    const position = rainbowHeadingContainer.current.getBoundingClientRect();
    if (scrollTop - offsetTop > 0 && position.top <= -40 && scale >= 1 && blur >= 0) {
      setScale(1 + (scrollTop - offsetTop) / 150);
      setOpacity(1 - (scrollTop - offsetTop) / 250);
      setBlur((scrollTop - offsetTop) / 10);
    } else {
      setScale(1);
      setBlur(0);
      setOpacity(1);
    }
  }, [scrollTop, offsetTop]);

  return (
    <STYLED_RAINBOWHEADINGCONTAINER style={containerStyle} ref={rainbowHeadingContainer} ref={RainbowHeadingContainer} data-slyck_id="OFTKI3mZajhhR7Lcln3B">
      <STYLED_RAINBOWHEADING className={"rainbow-text"} data-slyck_id="qmL0GkDxJhfi0A3j3Cd4">{text}</STYLED_RAINBOWHEADING>
    </STYLED_RAINBOWHEADINGCONTAINER>
  );

}
