/*************************************
* File generated by Slyck IDE
* Pricing Component
*************************************/

import { useState, useEffect, useLayoutEffect, useRef, useMemo, useCallback, Fragment } from 'react';

// state management
import { useDispatch, useSelector } from 'react-redux';
import { getTeamsPlanPrice, getProPlanPrice, getScrollTop, setScrollTop as _setScrollTop, setPlanPrice as _setPlanPrice, setScrollDirection as _setScrollDirection } from '../mainSlice';
//assets
import app from '../assets/app.png';
import firebase_template from '../assets/firebase_template.png';
import slyck_project_screen from '../assets/slyck_project_screen.png';
import slyck_logo_bw from '../assets/slyck_logo_bw.svg';
import components_thumb from '../assets/components_thumb.png';
import pages_thumb from '../assets/pages_thumb.png';
import elements_thumb from '../assets/elements_thumb.png';
import download_app_store from '../assets/download_app_store.svg';

// components
import { useNavigate } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";
import PriceBox from "../components/PriceBox";

import styled from 'styled-components';


/*************************************
* Styled components
*************************************/

const STYLED_38UU2O = styled.div`
  height: 100%;
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
`;
const STYLED_TIJK4V = styled.div`
  padding: 1.6rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 19.4rem);
`;
const STYLED_3IP0W5 = styled.div`
  max-width: var(--max-width);
  margin: 0 auto;
  text-align: center;
@media (max-width: 768px) {
    
      text-align: left;
    }
`;
const STYLED_ELQHUJ = styled.div`
  display: grid;
  margin: 3rem auto 0;
  max-width: 780px;
  column-gap: 2rem;
  grid-template-columns: repeat(2, 1fr);
  width: 100%;
@media (max-width: 768px) {
    
      grid-template-columns: 1fr;
      row-gap: 2rem;
    }
`;
const STYLED_T6JUSK = styled.div`
  font-size: var(--sub-heading-size);
  max-width: 54rem;
  line-height: 1.3;
  margin: 1.2rem auto 1.2rem;
`;
const STYLED_8QLH4I = styled.img`
  width: 16rem;
`;


/*************************************
* Component export
*************************************/

export default function Pricing(props) {

  // actions
  const dispatch = useDispatch();
  const setScrollTop = useCallback((payload) => dispatch({type: 'setScrollTop', payload}), [dispatch]);
  const setPlanPrice = useCallback((payload) => dispatch({type: 'setPlanPrice', payload}), [dispatch]);
  const setScrollDirection = useCallback((payload) => dispatch({type: 'setScrollDirection', payload}), [dispatch]);

  // variables
  const teamsPlanPrice = useSelector((state) => getTeamsPlanPrice(state, props));
  const proPlanFeatures = useMemo(() => ['All Starter features', 'Version Control', 'Save Components', 'Save Templates', 'Team management'], []);
  const teamsPlanFeatures = useMemo(() => ['All Pro features', 'Team management', 'Shared projects, templates + components', 'Multi User Projects'], []);
  const freePlanFeatures = useMemo(() => ['React development environment','Unlimited projects','Built-in preview with Chrome DevTools','Public Component library','Integrations (Redux, Firebase, Capacitor)'], []);
  const proPlanPrice = useSelector((state) => getProPlanPrice(state, props));
  const scrollTop = useSelector((state) => getScrollTop(state, props));
  const navigate = useNavigate();

  // side effects
  useEffect(() => {
    const { Paddle } = window;
    
    if (Paddle) {
      Paddle.Product.Prices(741066, (data) => {
        const priceSplit = data.price.gross.split('.');
        setPlanPrice({
          plan: 'pro',
          price: priceSplit.length && priceSplit[1] === '00' ? priceSplit[0] : data.price.gross
        });
      });
      Paddle.Product.Prices(741068, (data) => {
        const priceSplit = data.price.gross.split('.');
        setPlanPrice({
          plan: 'teams',
          price: priceSplit.length && priceSplit[1] === '00' ? priceSplit[0] : data.price.gross
        });
      });
    }
  }, [setPlanPrice]);

  useEffect(() => {
    window.gtag('set', 'page_path', '/pricing');
    window.gtag('event', 'page_view');
  }, []);

  useEffect(() => {
    setScrollTop(0);
  }, [setScrollTop]);
  
  /**
   * Handles the onScroll event for the  element.
   * @param {Object} event - event object passed from browser
   */
  const onScroll38uU2O = (event) => {
    
    setScrollTop(event.target.scrollTop);
    if (event.target.scrollTop > scrollTop) {
      setScrollDirection('down');
    } else {
      setScrollDirection('up');
    }
  }

  return (
    <STYLED_38UU2O onScroll={onScroll38uU2O} className={scrollTop <= 0 || !scrollTop ? 'scrolltop' : ''} data-slyck_id="38uU2ODjLVu1ea5VVuHV">
      <Header data-slyck_id="ZP21fdJ7wXlMeFN5ZLVr" />
      <STYLED_TIJK4V data-slyck_id="tIjK4VpZulI73HhNAlNE">
        <STYLED_3IP0W5 data-slyck_id="3iP0w5toLV7Bchxo5cpQ">
          <h1 data-slyck_id="mnepaJNO8Yl6YbAk9V82">Pricing</h1>
          <STYLED_T6JUSK data-slyck_id="T6juskV7GmtazkohigZ4">Download and use Slyck for free or upgrade to Slyck Pro for enterprise features.</STYLED_T6JUSK>
          {false && (
            <a href={"https://apps.apple.com/us/app/slyck-ide/id1560361129"} data-slyck_id="hkeHs6Jm8iKMW0l0PzP1">
              <STYLED_8QLH4I src={download_app_store} alt={"Download on the Mac App Store"} data-slyck_id="8Qlh4IDMHUPHaoVnSqz4" />
            </a>
          )}
        </STYLED_3IP0W5>
        <STYLED_ELQHUJ data-slyck_id="elQhujXi4640HcgCKZtT">
          <PriceBox btnText={"Download the app"} name={"Starter"} cycle={'forever'} btnColor={"#777"} onChoose={() => { window.location.href = "/" }} features={freePlanFeatures} price={"Free"} data-slyck_id="HX26i5IZxbAakLJKGI6y" />
          <PriceBox btnColor={'white'} btnText={"Upgrade to Pro"} features={proPlanFeatures} name={"Pro"} btnBackground={'#167ffe'} onChoose={() => {
  navigate('/upgrade', {replace: true})
}} cycle={"per user / month"} price={proPlanPrice} data-slyck_id="UdvqN0cMYffq348peG3V" />
          {false && (
            <PriceBox price={teamsPlanPrice} name={"Teams"} features={teamsPlanFeatures} data-slyck_id="t2jniPdjqDhFA6TDPwft" />
          )}
        </STYLED_ELQHUJ>
      </STYLED_TIJK4V>
      <Footer data-slyck_id="mbpKV1UDUEm4qnuABxqZ" />
    </STYLED_38UU2O>
  );

}
