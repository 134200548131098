/*************************************
* File generated by Slyck IDE
* Privacy Component
*************************************/

import { useState, useEffect, useLayoutEffect, useRef, useMemo, useCallback, Fragment } from 'react';

// state management
import { useDispatch, useSelector } from 'react-redux';
//assets
import app from '../assets/app.png';
import firebase_template from '../assets/firebase_template.png';
import slyck_project_screen from '../assets/slyck_project_screen.png';
import slyck_logo_bw from '../assets/slyck_logo_bw.svg';
import components_thumb from '../assets/components_thumb.png';
import pages_thumb from '../assets/pages_thumb.png';
import elements_thumb from '../assets/elements_thumb.png';
import download_app_store from '../assets/download_app_store.svg';

// components
import Header from "../components/Header";

import styled from 'styled-components';


/*************************************
* Styled components
*************************************/

const STYLED_PRIVACYSECTIONCOPY = styled.div`
  padding: 1.6rem;
  --max-width: 94rem;
`;
const STYLED_PRIVACYCONTENTCOPY = styled.div`
  & h2 {
    margin-top: 4rem;
    font-size: 3.8rem;
  }
  
  max-width: var(--max-width);
  margin: 3rem auto 0;
  font-size: 2rem;
  line-height: 1.5;
`;
const STYLED_PRIVACYDATECOPY = styled.div`
  font-size: 2.2rem;
  font-weight: 500;
  color: #999;
  margin-bottom: 3rem;
`;
const STYLED_PRIVACYSUMMARYCOPY = styled.div`
  font-size: 2.8rem;
  line-height: 1.3;
`;


/*************************************
* Component export
*************************************/

export default function Privacy(props) {

  // side effects
  useEffect(() => {
    window.gtag('set', 'page_path', '/privacy');
    window.gtag('event', 'page_view');
  }, []);

  return (
    <Fragment>
      <Header data-slyck_id="kRbkuBIY4YwlBh11ZnHk" />
      <STYLED_PRIVACYSECTIONCOPY data-slyck_id="8AkQl28sRi2GQok1v9UA">
        <STYLED_PRIVACYCONTENTCOPY data-slyck_id="u1IwXwUH9Dtq21djKVKC">
          <h1 data-slyck_id="wZ7EvJy068yFMTM7rVoZ">Privacy notice</h1>
          <STYLED_PRIVACYDATECOPY data-slyck_id="zbLWJ3QFXzFQ6JRvf9Sp">20 March 2021</STYLED_PRIVACYDATECOPY>
          <STYLED_PRIVACYSUMMARYCOPY data-slyck_id="Yg1lPJHcpDZqFGBu6XKB">This privacy notice informs you about the type of personal data that is collected and received through our website https://www.slyck.app (the “Website”). We will only collect and use personal data in ways that are described here, and in a way that is consistent with our obligations and your rights under the law.</STYLED_PRIVACYSUMMARYCOPY>
          <h2 data-slyck_id="5t7yYrx15wTEEl6S539N">Who we are</h2>
          <div data-slyck_id="IddGu0qxRruk4U8TlPjs">We are Human Era Ltd (“we“ or “us“), a limited company registered in the United Kingdom, with a registered address at 71-75 Shelton Street, London WC2H 9JQ and company number 12964827.</div>
          <h2 data-slyck_id="shcUxs6aXg7JCntz2dTi">What information do we collect?</h2>
          <p data-slyck_id="dQodv8S80aUlEKRhHXpH">In short: Some information — such as your Internet Protocol (IP) address and/or browser and device characteristics — is collected automatically when you visit our Website.</p>
          <p data-slyck_id="WryiM6BTNDT5gudUToBe">We automatically collect certain information when you visit, use or navigate the Website. This information does not reveal your specific identity (like your name or contact information) but may include device and usage information, such as your IP address, browser and device characteristics, operating system, language preferences, referring URLs, device name, country, location, information about how and when you use our Website and other technical information. This information is primarily needed to maintain the security and operation of our Website, and for our internal analytics and reporting purposes.</p>
          <p data-slyck_id="KULZTn8gTjcO4GcZmWIJ">Like many businesses, we also collect information through cookies and similar technologies.</p>
          <p data-slyck_id="XZlI7J19fhHkejy9ZXrX">The information we collect includes:</p>
          <div class={"indent"} data-slyck_id="GrcCRRLecySInHV6io9z">
            <p data-slyck_id="e5fMoO0ghMDy8oe4cZ3K">Log and Usage Data. Log and usage data is service-related, diagnostic, usage and performance information our servers automatically collect when you access or use our Website and which we record in log files. Depending on how you interact with us, this log data may include your IP address, device information, browser type and settings and information about your activity in the WebsiteÂ (such as the date/time stamps associated with your usage, pages and files viewed, searches and other actions you take such as which features you use), device event information (such as system activity, error reports (sometimes called 'crash dumps') and hardware settings).</p>
            <p data-slyck_id="TJhzfEBNAFe7S9gFgSQ9">Device Data. We collect device data such as information about your computer, phone, tablet or other device you use to access the Website. Depending on the device used, this device data may include information such as your IP address (or proxy server), device and application identification numbers, location, browser type, hardware model Internet service provider and/or mobile carrier, operating system and system configuration information.</p>
            <p data-slyck_id="nBeaGE60JAKJVRgYywCh">Location Data. We collect location data such as information about your device's location, which can be either precise or imprecise. How much information we collect depends on the type and settings of the device you use to access the Website. For example, we may use GPS and other technologies to collect geolocation data that tells us your current location (based on your IP address). You can opt out of allowing us to collect this information either by refusing access to the information or by disabling your Location setting on your device. Note however, if you choose to opt out, you may not be able to use certain aspects of the Services.</p>
          </div>
          <h2 data-slyck_id="ojWMxG3OHV1oSm8nS8wn">How do we use your information?</h2>
          <p data-slyck_id="ttZ2k7Ygcl7g4km7ZnhC">In short: We process your information for purposes based on legitimate business interests, the fulfillment of our contract with you, compliance with our legal obligations, and/or your consent.</p>
          <p data-slyck_id="iFjSVPo43mNCaAEBEfnX">We use personal information collected via our Website for a variety of business purposes described below. We process your personal information for these purposes in reliance on our legitimate business interests, in order to enter into or perform a contract with you, with your consent, and/or for compliance with our legal obligations. We indicate the specific processing grounds we rely on next to each purpose listed below.</p>
          <p data-slyck_id="4AOiscrtx0dVICgvrH3P">We use the information we collect or receive:</p>
          <div class={"indent"} data-slyck_id="KpJyELVTmPtNKC4MD20N">
            <p data-slyck_id="O9InJnIzQfDeu6s1nh3I">For other business purposes. We may use your information for other business purposes, such as data analysis, identifying usage trends, determining the effectiveness of our promotional campaigns and to evaluate and improve our Website, products, marketing and your experience. We may use and store this information in aggregated and anonymized form so that it is not associated with individual end users and does not include personal information. We will not use identifiable personal information without your consent.</p>
          </div>
          <h2 data-slyck_id="RrQGMaKrSrjfRR1OOVTb">Will your information be shared with anyone?</h2>
          <p data-slyck_id="w2UYzZdWBJftld9MdVda">In short: We only share information with your consent, to comply with laws, to provide you with services, to protect your rights, or to fulfill business obligations.</p>
          <p data-slyck_id="0Ioit8MQNWYFavkp5p49">We may process or share your data that we hold based on the following legal basis:</p>
          <div class={"indent"} data-slyck_id="oEuF3jNZ6bjfQEcCmYlL">
            <p data-slyck_id="rDoy1ec1Hd9zuOxEis6h">Consent: We may process your data if you have given us specific consent to use your personal information for a specific purpose.</p>
            <p data-slyck_id="aYjxuv3Ik6y17nOctJq6">Legitimate Interests: We may process your data when it is reasonably necessary to achieve our legitimate business interests.</p>
            <p data-slyck_id="WdIaDxl6n3ll0uLfnZaN">Performance of a Contract: Where we have entered into a contract with you, we may process your personal information to fulfill the terms of our contract.</p>
            <p data-slyck_id="wHLSJcOl85n2GZAfKSTS">Legal Obligations: We may disclose your information where we are legally required to do so in order to comply with applicable law, governmental requests, a judicial proceeding, court order, or legal process, such as in response to a court order or a subpoena (including in response to public authorities to meet national security or law enforcement requirements).</p>
            <p data-slyck_id="f510xFhjsssS1jFCehC0">Vital Interests: We may disclose your information where we believe it is necessary to investigate, prevent, or take action regarding potential violations of our policies, suspected fraud, situations involving potential threats to the safety of any person and illegal activities, or as evidence in litigation in which we are involved.</p>
          </div>
          <p data-slyck_id="PdiCL3tg1DzIjmbKyxL3">More specifically, we may need to process your data or share your personal information in the following situations:</p>
          <div class={"indent"} data-slyck_id="5XUVHlPz0wpzpoTLzfpe">
            <p data-slyck_id="tTZBTmKPssqYKYCQN6C2">Business Transfers. We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.</p>
          </div>
          <h2 data-slyck_id="KuNUTAEJltJcjdHPksiS">Do we use cookies and other tracking technologies?</h2>
          <p data-slyck_id="11XnDsTLczsShLy7zBPA">In short: We may use cookies and other tracking technologies to collect and store your information.</p>
          <p data-slyck_id="eWd5XsW7jEUuBWLkAkgV">We may use cookies and similar tracking technologies (like web beacons and pixels) to access or store information. Specific information about how we use such technologies and how you can refuse certain cookies is set out in our Cookie Notice.</p>
          <h2 data-slyck_id="Il7Rh0ZkYHmCymuOQsON">Is your information transferred internationally?</h2>
          <p data-slyck_id="iRK96XgKcaiQkIoa2KPG">In short: We may transfer, store, and process your information in countries other than your own.</p>
          <p data-slyck_id="MXy70NCByXxzKCiKSf5Z">Our servers are located in Europe. If you are accessing our Website from outside, please be aware that your information may be transferred to, stored, and processed by us in our facilities and by those third parties with whom we may share your personal information (see "Will your information be shared with anyone?" above), in and other countries.</p>
          <p data-slyck_id="K4weLykc0AZWUpTRfOj0">If you are a resident in the European Economic Area, then these countries may not necessarily have data protection laws or other similar laws as comprehensive as those in your country. We will however take all necessary measures to protect your personal information in accordance with this privacy notice and applicable law.</p>
          <h2 data-slyck_id="WI8YAAzPzomDxS34SvLI">How long do we keep your information?</h2>
          <p data-slyck_id="nnIztp8RM8FvJK8tv1XW">In short: We keep your information for as long as necessary to fulfill the purposes outlined in this privacy notice unless otherwise required by law.</p>
          <p data-slyck_id="Y9kmJZKAttuAEu4AKZ7F">We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy notice, unless a longer retention period is required or permitted by law (such as tax, accounting or other legal requirements). No purpose in this notice will require us keeping your personal information for longer than 6 months.</p>
          <p data-slyck_id="ljxxzF0OVDvQ2TrP818y">When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymize such information, or, if this is not possible (for example, because your personal information has been stored in backup archives), then we will securely store your personal information and isolate it from any further processing until deletion is possible.</p>
          <h2 data-slyck_id="Eqjd4Xg6cQHO5guNQ804">Updates to this notice</h2>
          <p data-slyck_id="qZyqopJUyG3YEtIhXIev">We may update this privacy notice from time to time. The updated version will be indicated by an updated "Revised" date and the updated version will be effective as soon as it is accessible. If we make material changes to this privacy notice, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review this privacy notice frequently to be informed of how we are protecting your information.</p>
          <h2 data-slyck_id="55yYHVlxe9lGEFYuNBjD">How to contact us</h2>
          <p data-slyck_id="DB1HsvLlakktZZQ9BsJI">
If you have any comments or inquiries about the information in this privacy notice, if you would like us to update your personal data, or to exercise your rights, please contact us by email at&nbsp;
            <a href={"mailto:dataprotection@slyck.app"} data-slyck_id="bo2GrPsbR9rWuYxTpc35">dataprotection@slyck.app</a>
. In addition, you always have the right to make a complaint at any time to a competent supervisory authority.
          </p>
        </STYLED_PRIVACYCONTENTCOPY>
      </STYLED_PRIVACYSECTIONCOPY>
    </Fragment>
  );

}
