/*************************************
* File generated by Slyck IDE
* HeadingBlock Component
*************************************/

import { useState, useEffect, useLayoutEffect, useRef, useMemo, useCallback, Fragment } from 'react';

// state management
import { useDispatch, useSelector } from 'react-redux';
//assets
import app from '../assets/app.png';
import firebase_template from '../assets/firebase_template.png';
import slyck_project_screen from '../assets/slyck_project_screen.png';
import slyck_logo_bw from '../assets/slyck_logo_bw.svg';
import components_thumb from '../assets/components_thumb.png';
import pages_thumb from '../assets/pages_thumb.png';
import elements_thumb from '../assets/elements_thumb.png';
import download_app_store from '../assets/download_app_store.svg';

// components

import styled from 'styled-components';


/*************************************
* Styled components
*************************************/

const STYLED_NKR2KX = styled.div`
  text-align: center;
  max-width: 84rem;
  margin: 0 auto 4rem;
  font-size: var(--sub-heading-size);
  padding: 1.6rem;
@media (max-width: 768px) {
    
      text-align: left;
      font-size: 2.6rem;
      margin: 0 auto 2rem;
    }
`;
const STYLED_1PFWSW = styled.h2`
  font-weight: 600;
  margin-bottom: 0.8rem;
`;


/*************************************
* Component export
*************************************/

export default function HeadingBlock(props) {
  const { title, subtitle, className } = props;

  return (
    <STYLED_NKR2KX className={className} data-slyck_id="nKr2kxKtNAnNhH7Xld4A">
      <STYLED_1PFWSW data-slyck_id="1pFwSwgV3QS3ClNRxqzW">{title}</STYLED_1PFWSW>
      <div data-slyck_id="ERllTg9FZfChfOE7WAOO">{subtitle}</div>
    </STYLED_NKR2KX>
  );

}
