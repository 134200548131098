/*************************************
* File generated by Slyck IDE
* Header Component
*************************************/

import { useState, useEffect, useLayoutEffect, useRef, useMemo, useCallback, Fragment } from 'react';

// state management
import { useDispatch, useSelector } from 'react-redux';
import { getUser } from '../userSlice';
//assets
import app from '../assets/app.png';
import firebase_template from '../assets/firebase_template.png';
import slyck_project_screen from '../assets/slyck_project_screen.png';
import slyck_logo_bw from '../assets/slyck_logo_bw.svg';
import components_thumb from '../assets/components_thumb.png';
import pages_thumb from '../assets/pages_thumb.png';
import elements_thumb from '../assets/elements_thumb.png';
import download_app_store from '../assets/download_app_store.svg';

// components
import { getAuth, signOut } from "firebase/auth";
import { Link } from "react-router-dom";
import { IoCloudDownloadOutline } from "react-icons/io5";

import styled from 'styled-components';


/*************************************
* Styled components
*************************************/

const STYLED_HEADERCONTENT = styled.div`
  padding: 1.6rem;
  width: 100%;
  align-items: center;
  position: sticky;
  background-color: rgba(255,255,255,0.7);
  top: 0;
  z-index: 2;
  box-shadow: 0 1px rgba(0,0,0,0.1);
  backdrop-filter: blur(40px);
  height: 6.4rem;
  display: flex;

  .scrolltop & {
    box-shadow: none;
    position: static;
  }
`;
const STYLED_CONTENT = styled.div`
  max-width: var(--max-width);
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;
const STYLED_NAV = styled.div`
  display: flex;
  align-items: center;
`;
const STYLED_IMM5C8 = styled.button`
  background: none;
  appearance: none;
  border: none;
  margin: 0;
  padding: 0;
  font-weight: 400;
  font-size: inherit;
  font-family: inherit;
`;
const STYLED_DOWNLOAD = styled.a`
  background-color: var(--primary-color);
  padding: 0.8rem 1.6rem;
  color: white;
  border-radius: 0.8rem;
  font-weight: 400;
  display: flex;
  align-items: center;
  column-gap: 1.2rem;
@media (max-width: 768px) {
    
      display: none;
    }
  
  & svg {
    font-size: 2.4rem;
  }
  
  .scrolltop & {
    display: none;
  }
`;
const STYLED_HOMELINK = styled(Link)`
  margin-left: -0.4rem;
`;
const STYLED_LOGO = styled.img`
  width: 3rem;
`;
const STYLED_PK8KDA = styled.div`
  text-align: left;
`;
const STYLED_MYG5KA = styled.div`
  font-size: 1.1rem;
  font-weight: 200;
`;


/*************************************
* Component export
*************************************/

export default function Header(props) {

  // variables
  const user = useSelector((state) => getUser(state, props));
  
  /**
   * Handles the onClick event for the undefined element.
   * @param {Object} event - event object passed from browser
   */
  const onClickimM5C8 = (event) => {
    
    const auth = getAuth();
    signOut(auth);
  }

  return (
    <STYLED_HEADERCONTENT data-slyck_id="3305UoWmeWDJ1I2GQlrK">
      <STYLED_CONTENT data-slyck_id="xZJeaQHvTyZkCGsC90zF">
        <STYLED_NAV className={"nav"} data-slyck_id="wDDmserlwYcyGqfOZ0Zv">
          <STYLED_HOMELINK to={"/"} data-slyck_id="X0iwX0vjl4WT4UldD6pF">
            <STYLED_LOGO src={slyck_logo_bw} data-slyck_id="9BSXepKF7xQOk538aFQg" />
          </STYLED_HOMELINK>
          {false && (
            <Link to={"/templates"} data-slyck_id="qq5kXDxL346NyhAMtfaF">Templates</Link>
          )}
          <Link to={"/pricing"} data-slyck_id="XmQYRZz97aDyoltz2gC7">Pricing</Link>
        </STYLED_NAV>
        {user.authState === 'SIGNED_IN' && (
          <STYLED_IMM5C8 data-slyck_id="imM5C875raBiS5qNDRbF" onClick={onClickimM5C8}>Sign out</STYLED_IMM5C8>
        )}
        {false && (
          <STYLED_DOWNLOAD href={"https://download.slyck.app/download/mac_arm64"} data-slyck_id="qs3Xw4rrUfBNypGvjRqj">
            <IoCloudDownloadOutline data-slyck_id="OJA087SKDyFhJ4efz7Sz" />
            <STYLED_PK8KDA data-slyck_id="PK8kdafc8eM1gV3gCO7u">
              <div data-slyck_id="Lu1MQymUPJK7LvoDs6QJ">Download for macOS</div>
              <STYLED_MYG5KA data-slyck_id="MYG5KaH2cqkzkEb4qZM5">Apple silicon</STYLED_MYG5KA>
            </STYLED_PK8KDA>
          </STYLED_DOWNLOAD>
        )}
      </STYLED_CONTENT>
    </STYLED_HEADERCONTENT>
  );

}
